import { Fragment, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import useApi from 'common/hooks/useApi'
import MainLayout from 'containers/MainLayout'
import {
  PageTitle,
  PageWrapper,
  SpacedTextButton,
  StyledGrid
} from 'components/Styled'
import routes from 'services/api/routes'
import { setAppointments } from './accountSlice'
import { AccountPageTitle } from './styled'
import Appointment from './components/Appointment'

export default function AccountPage () {
  const [isLoading, httpClient] = useApi()
  const dispatch = useDispatch()
  const navigate = useHistory()
  const { appointments } = useSelector(state => state.account)
  const { user } = useSelector(state => state.app)
  const firstName = user?.name?.split(' ')?.shift()
  const showAppointments = !isLoading && appointments?.length > 0

  const fetchAppointments = useCallback(async () => {
    httpClient
      .get(routes.users.appointments)
      .then(data => {
        if (Array.isArray(data)) dispatch(setAppointments(data))
      })
  }, [])

  // didMount: fetch appointments
  useEffect(() => {
    fetchAppointments()
  }, []) // eslint-disable-line

  return (
    <MainLayout title={<AccountPageTitle />}>
      <div className='app-box'>
        <PageWrapper>
          <StyledGrid container spacing={3} className='grid-wrapper'>
            <Grid item md={9} xs={12}>
              <PageTitle big mb={0}>
                Olá, <strong>{firstName}</strong>!
              </PageTitle>
              <p>
                Aqui você poderá gerenciar seus agendamentos e consultar seu histórico.
              </p>
            </Grid>

            <Grid item xs>
              <SpacedTextButton
                fullWidth
                color='primary'
                variant='contained'
                onClick={() => navigate.push('/')}
              >
                MARCAR NOVA CONSULTA
              </SpacedTextButton>
            </Grid>

            {isLoading && <span>Carregando seus agendamentos...</span>}
            {showAppointments && (
              <Fragment>
                <Grid item xs={12}>
                  <PageTitle bold small>Próximas consultas</PageTitle>
                </Grid>
                {appointments?.map(appointment => (
                  <Appointment
                    key={String(appointment.id)}
                    data={appointment}
                    onRefreshList={fetchAppointments}
                    user={user}
                  />
                ))}
              </Fragment>
            )}
          </StyledGrid>
        </PageWrapper>
      </div>
    </MainLayout>
  )
}
