import { memo, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Menu } from './styled'
import { SCHEDULER_STEPS, SCHEDULER_STEPS_INDEX } from '../constants'
import { setDate, setUser } from '../schedulings.store'
import StepperItem from './components/StepperItem'

function Stepper ({ selected }) {
  const dispatch = useDispatch()
  const appUser = useSelector(state => state.app.user)
  const date = useSelector(state => state.schedulings[SCHEDULER_STEPS.Date.StepNumber])
  const selectedProfessionalId = useSelector(state => state.schedulings[SCHEDULER_STEPS.Professional.StepNumber]?.id)
  const bookOptions = useSelector(state => state.app.professionals?.find(item => item?.id === selectedProfessionalId)?.bookOptions || [])

  const stepUser = useSelector(state => state.schedulings[SCHEDULER_STEPS.User.StepNumber])
  const values = useSelector(state => state.schedulings)
  const { user } = useSelector(state => state.app)

  const disabledDateFilter = useCallback((date) => {
    const availableDates = bookOptions
      ?.map(item => item.originalDate.substr(0, 10)) || []
    return !availableDates.includes(date)
  }, [bookOptions])

  const handleDateSelected = useCallback(({ iso }) => {
    const isAfterDateStep = selected > SCHEDULER_STEPS_INDEX.DATE
    if (isAfterDateStep) return
    dispatch(setDate({ day: iso.substr(0, 10), hour: null }))
  }, [])

  const steps = useMemo(() => Object.entries(SCHEDULER_STEPS).map(([slug, step]) => ({
    getValue: step.getValue,
    name: step.name,
    index: step.StepNumber,
    slug
  })), [])

  // if user logout, ensure clear user step's data
  useEffect(() => {
    if (!appUser.name && stepUser.name?.length > 0) {
      dispatch(setUser({ name: null }))
    }
  }, [appUser.name])

  useEffect(() => {
    if (!stepUser?.name && user?.name) {
      dispatch(setUser(user.name))
    }
  }, [user])

  return (
    <Menu>
      {steps.map((step, index) => {
        const isDateStep = step.index === SCHEDULER_STEPS_INDEX.DATE
        const isSelected = selected === index

        return (
          <StepperItem
            key={step.slug}
            selected={isSelected}
            onDateSelected={handleDateSelected}
            date={isDateStep ? date : null}
            onDisabledDateFilter={isDateStep ? disabledDateFilter : null}
            step={step}
            value={values[index]}
          />
        )
      })}
    </Menu>
  )
}

Stepper.propTypes = {
  selected: PropTypes.number.isRequired
}

export default memo(Stepper)
