import PropTypes from 'prop-types'
import MaskedInput from 'react-input-mask'

const MaskedShortDateInput = ({ inputRef, ...rest }) => {
  return <MaskedInput autoComplete='off' {...rest} ref={inputRef} mask='99/9999' />
}

MaskedShortDateInput.propTypes = {
  inputRef: PropTypes.func.isRequired
}

export default MaskedShortDateInput
