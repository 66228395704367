import { Grid, IconButton } from '@material-ui/core'
import styled from 'styled-components'

export const NativeLoginSubmit = styled(IconButton)`
  background-color: black !important;
  border-radius: 0px !important;
  margin: 31px 8px 0px !important;
  height: 50px;
  width: 72px;
`

export const SocialButtonsWrapper = styled(Grid).attrs({
  container: true,
  spacing: 2
})`
  padding-right: 88px;
`
