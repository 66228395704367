import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  appointments: []
}

export const accountSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetAccountState: (state, action) => {
      state = { ...initialState }
    },
    setAppointments: (state, action) => {
      state.appointments = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  resetAccountState,
  setAppointments
} = accountSlice.actions

export default accountSlice.reducer
