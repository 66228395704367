import { useState } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import defaultAvatar from 'assets/images/avatar.png'
import MuiAlertDialog from 'components/MuiAlertDialog'
import { Avatar } from 'components/Styled'
import { SCHEDULER_STEPS } from '../constants'
import { setProfessional } from '../schedulings.store'
import { ProfessionalCard, ProfessionalInfo, ProfessionalsList, StepTitle } from './styled'

const DIALOG_INITIAL_DATA = {
  open: false,
  title: '',
  message: '',
  value: null
}

function Professional ({
  onNextStep: handleNextStep,
  title
}) {
  const dispatch = useDispatch()
  const { professionals } = useSelector(state => state.app)
  const value = useSelector(state => state.schedulings[SCHEDULER_STEPS.Professional.StepNumber])
  const [dialog, setDialog] = useState(DIALOG_INITIAL_DATA)

  const handleConfirmed = (professional = {}) => {
    dispatch(setProfessional(professional))
    handleNextStep()
  }

  const handleSelect = (professional = {}) => {
    setDialog({
      open: true,
      title: 'Confirmação',
      message: `Você selecionou Dr(a). ${professional?.name}. Deseja confirmar e continuar?`,
      value: professional
    })
  }

  return (
    <div>
      <StepTitle>{title}</StepTitle>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProfessionalsList>
            {professionals?.map(doctor => (
              <li
                key={String(doctor?.id)}
                onClick={() => handleSelect(doctor)}
                className={value?.id === doctor?.id ? 'selected' : ''}
              >
                <ProfessionalCard>
                  <div className='info'>
                    <Avatar alt={doctor?.name} size={60} src={doctor?.avatar || defaultAvatar} />
                    <ProfessionalInfo>Dr(a). {doctor?.name}</ProfessionalInfo>
                  </div>
                </ProfessionalCard>
              </li>
            ))}
          </ProfessionalsList>
        </Grid>
      </Grid>

      {dialog.open && (
        <MuiAlertDialog
          open
          title={dialog.title}
          message={dialog.message}
          onCancel={() => setDialog(DIALOG_INITIAL_DATA)}
          onConfirm={() => handleConfirmed(dialog.value)}
          labelConfirm='Confirmar'
        />
      )}
    </div>
  )
}

Professional.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

export default Professional
