import PropTypes from 'prop-types'
import MaskedInput from 'react-input-mask'

const MaskedCepInput = ({ inputRef, ...rest }) => {
  return <MaskedInput autoComplete='off' {...rest} ref={inputRef} mask='99999-999' />
}

MaskedCepInput.propTypes = {
  inputRef: PropTypes.func.isRequired
}

export default MaskedCepInput
