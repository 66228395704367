import { REQUIRED } from 'common/messages'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(REQUIRED)
    .email('Endereço de e-mail inválido.'),
  password: Yup.string()
    .required(REQUIRED)
    .min(6, 'Informe uma senha com no mínimo 6 caracteres.')
    .max(12, 'Informe uma senha com no máximo 12 caracteres.'),
  recoveryCode: Yup.string().when('recoveryEmail', {
    is: (value) => value?.length > 0,
    then: (schema) => schema.required('Informe o código')
  }),
  recoveryEmail: Yup.string()
    .email('Endereço de e-mail inválido.'),
  recoveryNewPassword: Yup.string().when('recoveryEmail', {
    is: (value) => value?.length > 0,
    then: (schema) => schema.min(6).max(12).required('Informe uma nova senha com 6 a 12 caracteres.')
  }),
  recoveryNewPasswordConfirm: Yup.string().when('recoveryNewPassword', {
    is: (value) => value?.length > 0,
    then: (schema) => schema.required('Confirme corretamente a sua nova senha.')
  })
})
