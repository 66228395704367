import styled from 'styled-components'
import { Box, Button, MenuItem, Select } from '@material-ui/core'
import LogoImage from 'assets/images/logo-fvg-white.png'
import LogoFooterImage from 'assets/images/logo-fvg-footer.png'
import PageTitleImage from 'assets/images/schedulings-page-title.png'
import { mobile, notMobile } from 'theme/responsive'

export const FooterContainer = styled.div`
  color: white;
  display: flex;
  background-color: ${({ theme }) => theme.palette.primary.main};
  justify-content: center;
  padding: 80px 0;

  ${mobile} {
    padding: 20px 0;

    h6 {
      font-weight: 600;
      margin-top: 15px;
    }
  }
`

export const FooterLinks = styled.ul`
  padding: 0;
  margin: 10px 0;
  list-style: none;

  li {
    font-size: 14px;
    margin: 8px 0;
  }
`

export const Header = styled.div`
  background: url('/images/header-bg.png') right bottom no-repeat;
  background-size: cover;
  display: flex;
  height: 194px;
  min-height: 194px;
  width: 100%;

  ${mobile} {
    min-height: 125px;
  }

  .bg-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;

    ${mobile} {
      img {
        max-width: 100%;
        margin: 55px 0px 0px 0px;
      }
    }
  }
`

export const Logo = styled.img.attrs({
  src: LogoImage
})``

export const LogoFooter = styled.img.attrs({
  src: LogoFooterImage
})``

export const PageTitle = styled.img.attrs({
  src: PageTitleImage
})`
  margin: 70px 0 0 95px;
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const RegionItem = styled(MenuItem)`
  font-size: 14px !important;
`

export const RegionSelector = styled(Select).attrs({
  MenuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  }
})`
  max-height: 25px;
  margin-left: 3px;

  div, svg {
    color: #FAFAFA;
  }

  [role=button] {
    font-size: 14px;
    font-weight: 600;
    margin-left: 4px;
    margin-top: 2px;
  }

  svg {
    margin-top: 4px;
  }
`

export const SignInButton = styled(Button).attrs({
  variant: 'contained'
})`
  background-color: white;
  font-size: 11px !important;
  padding: 3px 16px 2px !important;
  margin-left: 20px !important;
  
  ${notMobile} {
    span {
      letter-spacing: 0.15em;
    }
  }
`

export const SocialBox = styled(Box)`
  padding: 10px 0;
  svg { margin-right: 15px }
`

export const Topbar = styled.div`
  background-color: #212121;
  display: flex;
  height: 50px;
  min-height: 50px;
  width: 100%;

  & > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    svg {
      font-size: 15px;
      margin-right: 3px;
    }

    .content {
      align-items: center;
      color: #FAFAFA;
      display: inline-flex;
      font-size: 11px;
      font-weight: 600;

      a {
        color: white;
        text-decoration: none;
        transition: 0.2s;

        &:hover {
          color: #ddd;
        }
      }

      ${mobile} {
        hr { display: none; }
        &:first-child {
          a:last-child { display: none; }
        }
      }
    }

    hr {
      background-color: #FAFAFA;
      margin: 0 20px;
      height: 20px;
    }
  }
`

export const UserBox = styled(Box)`
  align-items: center;
  color: white !important;
  display: inline-flex;
  margin-left: 10px !important;

  svg {
    width: 22px;
    height: 22px;
  }
`

export const UserMenu = styled(Button)`
  align-items: center;
  color: white !important;
  display: inline-flex;

  svg {
    margin-right: 10px !important;
  }
`
