import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { mobile } from 'theme/responsive'
import { BookStatusColors } from './constants'

export const AppointmentCard = styled(Grid).attrs({
  container: true,
  item: true
})`
  border: 1px solid #ddd;
  margin: 5px 12px !important;
  padding: 20px;

  ${mobile} {
    .action-buttons {
      margin: 15px 0 0 0 !important;
      width: 100% !important;
    }

    .avatar {
      margin: 0 !important;
      width: 100% !important;

      img {
        width: 140px;
        height: auto;
      }
    }
  }
`

export const AppointmentStatus = styled(Typography).attrs({
  variant: 'body1'
})`
  align-items: center;
  color: ${({ status }) => BookStatusColors[status] || '#666'};
  display: inline-flex;
  margin-bottom: 10px !important;

  svg {
    margin-right: 5px;
  }
`
