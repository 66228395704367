/**
 * Transform date to its timezone variation
 * @param {Date} date
 */
function getTzDate (date) {
  if (!(date instanceof Date)) throw new Error('Informed date must be an instance of Date.')
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000)
}

export default getTzDate
