import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import useApi from 'common/hooks/useApi'
import { setProfessionalBook } from 'modules/app/appSlice'
import routes from 'services/api/routes'
import {
  addMonths,
  endOfMonth,
  formatISO,
  isBefore,
  startOfMonth,
  subDays,
  subMonths
} from 'date-fns'

const today = new Date()

export default function useCalendarNavigation (dependencies = null, professionalId = 0, setBookOptions) {
  const [, httpClient] = useApi()
  const dispatch = useDispatch()
  const dateRange = useRef({
    startAt: startOfMonth(today),
    endAt: endOfMonth(today)
  }).current

  useEffect(() => {
    if (Array.isArray(dependencies) && !dependencies.length) return
    const calendarButtons = document
      .querySelectorAll('[aria-label="calendar"] > div:first-child button')

    if (calendarButtons.length === 2) {
      const [previousButton, nextButton] = calendarButtons
      const requestAvailability = (startAt, endAt) => {
        if (isBefore(startAt, today)) startAt = today
        if (isBefore(endAt, today)) return

        const params = {
          startAt: `${startAt?.toISOString()?.slice(0, 10)}T00:00:00.000Z`,
          endAt: `${formatISO(endAt)?.slice(0, 10)}T23:59:59.000Z`
        }

        // request from API
        httpClient
          .get(routes.professionals.getAvailability(professionalId), { params })
          .then(data => {
            if (!setBookOptions) {
              dispatch(setProfessionalBook({ id: professionalId, data }))
            } else {
              setBookOptions(data)
            }
          })
      }

      const previousEventListener = () => {
        dateRange.startAt = subMonths(dateRange.startAt, 1)
        dateRange.endAt = subMonths(dateRange.endAt, 1)
        requestAvailability(dateRange.startAt, dateRange.endAt)
      }
      const nextEventListener = () => {
        dateRange.startAt = addMonths(dateRange.startAt, 1)
        dateRange.endAt = endOfMonth(subDays(addMonths(dateRange.endAt, 1), 1))
        requestAvailability(dateRange.startAt, dateRange.endAt)
      }

      previousButton.addEventListener('click', previousEventListener)
      nextButton.addEventListener('click', nextEventListener)

      return () => {
        previousButton.removeEventListener('click', previousEventListener)
        nextButton.removeEventListener('click', nextEventListener)
      }
    }
  }, dependencies)

  return null
}
