import { isValid } from 'date-fns'

export default function internationalizeDate (date = '') {
  const parts = date.split('/')
  if (parts.length !== 3) {
    console.error('Data informada precisa estar em formato brasileiro. Ex: 01/01/2021')
    return null
  }

  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`

  if (!isValid(new Date(formattedDate))) {
    console.error('Data informada é inválida.')
    return null
  }

  return formattedDate
}
