import { useState } from 'react'
import apiClient from 'services/api'

export default function useApi () {
  const [loading, setLoading] = useState(false)

  const handleExceptions = (httpRoute, error, options) => {
    const message = error?.response?.data?.message || error.message
    if (typeof options.onError === 'function') {
      options.onError(message)
    }

    if (process.env.NODE_ENV !== 'production') {
      console.error('@useGet', { httpRoute, error: message })
    }
  }

  const get = async (httpRoute = '', config = null, options = {}) => {
    setLoading(true)

    try {
      const { data } = await apiClient.get(httpRoute, config)
      return data
    } catch (error) {
      if (options.unhandled) throw error
      handleExceptions(httpRoute, error, options)
      return null
    } finally {
      setLoading(false)
    }
  }

  const post = async (httpRoute = '', payload = {}, options = {}) => {
    setLoading(true)

    try {
      const { data } = await apiClient.post(httpRoute, payload)
      return data
    } catch (error) {
      if (options.unhandled) throw error
      handleExceptions(httpRoute, error, options)
      return null
    } finally {
      setLoading(false)
    }
  }

  const patch = async (httpRoute = '', payload = {}, options = {}) => {
    setLoading(true)

    try {
      const { data } = await apiClient.patch(httpRoute, payload)
      return data
    } catch (error) {
      if (options.unhandled) throw error
      handleExceptions(httpRoute, error, options)
      return null
    } finally {
      setLoading(false)
    }
  }

  const clearBearerToken = () => apiClient.clearBearerToken()
  const setBearerToken = token => apiClient.setBearerToken(token)

  return [loading, { get, post, patch, clearBearerToken, setBearerToken }]
}
