import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid } from '@material-ui/core'
import useApi from 'common/hooks/useApi'
import MainLayout from 'containers/MainLayout'
import routes from 'services/api/routes'
import { setProfessionals } from 'modules/app/appSlice'
import Stepper from './Stepper'
import useScheduler from './useScheduler'
import Checkout from './steps/Checkout'
import Confirmed from './steps/Confirmed'
import Date from './steps/Date'
import Modality from './steps/Modality'
import Professional from './steps/Professional'
import User from './steps/User'
import { SCHEDULER_STEPS, SCHEDULER_STEPS_INDEX } from './constants'
import { Footer, Wrapper } from './styled'

export default function Scheduler () {
  const maxStep = 5
  const dispatch = useDispatch()
  const [, httpClient] = useApi()
  const {
    handleStepBack,
    handleStepNext,
    isNotAnswered,
    step
  } = useScheduler({ maxStep })

  const {
    transactionId,
    transactionValue
  } = useSelector(state => state.schedulings[SCHEDULER_STEPS_INDEX.CHECKOUT])

  const StepComponent = useCallback(() => {
    switch (step) {
      case (SCHEDULER_STEPS.Modality.StepNumber):
        return <Modality title='Qual a modalidade de consulta de sua preferência?' />
      case (SCHEDULER_STEPS.Professional.StepNumber):
        return <Professional title='Selecione o profissional:' onNextStep={handleStepNext} />
      case (SCHEDULER_STEPS.Date.StepNumber):
        return <Date title='Selecione o melhor dia e horário:' onNextStep={handleStepNext} />
      case (SCHEDULER_STEPS.User.StepNumber):
        return <User title='Já possui um cadastro? Faça o login:' />
      case (SCHEDULER_STEPS.Checkout.StepNumber):
        return !transactionId
          ? <Checkout />
          : <Confirmed transactionId={transactionId} transactionValue={transactionValue} />
      default:
        return null
    }
  }, [step, transactionId])

  const isFirstStep = step === 0
  const isLastStep = step === (maxStep - 1)

  useEffect(() => {
    httpClient
      .get(routes.professionals.getAll)
      .then(data => dispatch(setProfessionals(data)))
  }, [])

  return (
    <MainLayout>
      <>
        <div className='app-box'>
          <Wrapper>
            <Grid item md={3} xs={12}>
              <Stepper selected={step} />
            </Grid>

            <Grid item md={9} xs={12}>
              <StepComponent />
            </Grid>
          </Wrapper>
        </div>
        <Footer>
          {step >= 0 && (
            <Button
              color='primary'
              variant='outlined'
              disabled={isFirstStep}
              onClick={handleStepBack}
            >
              Anterior
            </Button>
          )}
          <Button
            color='primary'
            variant='contained'
            disabled={isLastStep || isNotAnswered}
            onClick={handleStepNext}
          >
            Próximo
          </Button>
        </Footer>
      </>
    </MainLayout>
  )
}
