export const APPOINTMENT_STATUS = {
  CANCELED: 'canceled',
  CONFIRMED: 'confirmed',
  PAID: 'paid',
  SCHEDULED: 'scheduled'
}

const BookStatus = {
  canceled: {
    color: 'grey',
    text: 'CANCELADO'
  },
  confirmed: {
    color: '#59BF40',
    text: 'CONFIRMADO'
  },
  paid: {
    color: '#2674BB',
    text: 'AGENDADO E PAGO'
  },
  scheduled: {
    color: '#93BBC2',
    text: 'AGENDADO'
  }
}

const BookStatusColors = {}
Object.keys(BookStatus).forEach(key => {
  BookStatusColors[key] = BookStatus[key].color
})

export {
  BookStatus,
  BookStatusColors
}
