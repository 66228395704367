import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { mobile } from 'theme/responsive'

export const CardGrid = styled(Grid).attrs({
  container: true,
  item: true,
  spacing: 2,
  xs: true
})`
  ${mobile} {
    margin: 0px !important;
  }
`
