import { Button, Grid, MenuItem } from '@material-ui/core'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { states } from 'common/data/brazilStates'
import useApi from 'common/hooks/useApi'
import InputAutocomplete from 'components/InputAutocomplete'
import InputSelect from 'components/InputSelect'
import InputText from 'components/InputText'
import MaskedCepInput from 'components/MaskedCepInput'
import routes from 'services/api/routes'
import { validationSchema } from './validationSchema'
import { useEffect, useState } from 'react'
import { StyledGrid } from 'components/Styled'

function Address (props) {
  const [requestLoading, httpClient] = useApi()
  const [cities, setCities] = useState([])
  const { errors, handleChange, submitForm, values, ...formik } = useFormik({
    initialValues: {
      city: '',
      neighborhood: '',
      state: '',
      street: '',
      streetNumber: '',
      complement: '',
      zipcode: ''
    },
    onSubmit: values => {
      values.zipcode = values.zipcode.replace(/[\D]/g, '')
      props.onSubmit('address', values)
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema
  })

  // didUpdate: state selected
  useEffect(() => {
    if (!values.state?.length) return

    httpClient
      .get(routes.locations.getCities(values.state))
      .then(data => { Array.isArray(data) && setCities(data) })
  }, [values.state])

  return (
    <StyledGrid container spacing={2} mt={25}>
      <Grid item md={4} xs={12}>
        <InputSelect
          fullWidth
          required
          label='ESTADO'
          name='state'
          formik={formik}
          onChange={handleChange}
          value={values.state || ''}
          inputProps={{ 'data-testid': 'input-address-state' }}
        >
          {states.map(value => (
            <MenuItem key={String(value)} value={value}>
              {value}
            </MenuItem>
          ))}
        </InputSelect>
      </Grid>

      <Grid item md={4} xs={12}>
        <InputAutocomplete
          disablePortal
          freeSolo
          fullWidth
          required
          disabled={!values.state || requestLoading}
          getOptionLabel={option => option.name || ''}
          loading={requestLoading}
          label='CIDADE'
          placeholder={!cities.length ? 'Selecione o Estado' : 'Digite a cidade'}
          name='city'
          formik={formik}
          clearText='Limpar'
          options={cities}
          onChange={handleChange}
        />
      </Grid>

      <Grid item mb={20} md={4} xs={12}>
        <InputText
          fullWidth
          required
          label='BAIRRO'
          name='neighborhood'
          testId='input-address-neighborhood'
          error={errors.neighborhood}
          inputProps={{ maxLength: 45 }}
          onChange={handleChange}
          value={values.neighborhood}
        />
      </Grid>

      <Grid item mb={20} md={6} xs={8}>
        <InputText
          fullWidth
          required
          label='Endereço'
          name='street'
          testId='input-address-street'
          error={errors.street}
          inputProps={{ maxLength: 60 }}
          onChange={handleChange}
          value={values.street}
        />
      </Grid>

      <Grid item mb={20} md={2} xs={4}>
        <InputText
          fullWidth
          required
          label='Número'
          name='streetNumber'
          testId='input-address-streetNumber'
          error={errors.streetNumber}
          inputProps={{ maxLength: 10 }}
          onChange={handleChange}
          value={values.streetNumber}
        />
      </Grid>

      <Grid item mb={20} md={4} xs={12}>
        <InputText
          fullWidth
          label='Complemento'
          name='complement'
          testId='input-address-complement'
          error={errors.complement}
          inputProps={{ maxLength: 60 }}
          onChange={handleChange}
          value={values.complement}
        />
      </Grid>

      <Grid item mb={20} md={3} xs={12}>
        <InputText
          fullWidth
          required
          label='CEP'
          name='zipcode'
          testId='input-address-zipcode'
          error={errors.zipcode}
          onChange={handleChange}
          value={values.zipcode}
          InputProps={{
            inputComponent: MaskedCepInput
          }}
        />
      </Grid>

      <Grid container item justifyContent='flex-end'>
        <Button
          color='primary'
          variant='contained'
          onClick={submitForm}
        >
          CONTINUAR
        </Button>
      </Grid>
    </StyledGrid>
  )
}

Address.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default Address
