import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { SCHEDULER_STEPS, modalities } from '../constants'
import { setModality } from '../schedulings.store'
import { OptionButton, StepTitle } from './styled'

function Modality ({
  title
}) {
  const dispatch = useDispatch()
  const value = useSelector(state => state.schedulings[SCHEDULER_STEPS.Modality.StepNumber])
  const handleSelect = value => dispatch(setModality(value))

  return (
    <div>
      <StepTitle>{title}</StepTitle>
      <Grid container spacing={3}>
        {modalities.map(({ name }) => (
          <Grid key={name} item md={6} xs={12}>
            <OptionButton
              className={value === name ? 'selected' : ''}
              onClick={() => handleSelect(name)}
            >
              {name}
            </OptionButton>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

Modality.propTypes = {
  title: PropTypes.string.isRequired
}

export default Modality
