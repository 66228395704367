import { Card } from '@material-ui/core'
import styled from 'styled-components'
import { mobile } from 'theme/responsive'

export const CheckoutCard = styled(Card).attrs({
  variant: 'outlined'
})`
  button {
    padding: 15px;
  }

  p.footer {
    text-align: center;
    margin-top: 12px;
  }

  .heading {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    text-align: center;
  }

  .items {
    font-weight: 400;

    .total {
      font-weight: 600;
    }

    ${mobile} {
      .rccs {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
      }
    }
  }

  .MuiCardContent-root:last-child {
    padding-bottom: 12px;
  }

  .price {
    text-align: right;
  }
`
