import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  city: Yup.string().required('Informe o nome de sua Cidade.'),
  neighborhood: Yup.string().required('Informe o nome de seu Bairro.'),
  state: Yup.string().required('Informe o nome do Estado onde mora.'),
  street: Yup.string().required('Informe o seu endereço.'),
  streetNumber: Yup.string().required('Informe o número de sua residência.'),
  complement: Yup.string(),
  zipcode: Yup.string().required('Informe o seu CEP.')
})
