import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom'
import useApi from 'common/hooks/useApi'
import routes from 'services/api/routes'
import PrivateRoute from './PrivateRoute'
import PublicRestrictRoute from './PublicRestrictRoute'
import { PageRoutes } from './routes'
import { logoutUser, setUser, setUserLocalIsChecked } from '../appSlice'
import { STORAGE_KEYS } from '../constants'

// Pages
import AccountPage from 'modules/account'
import LoginPage from 'modules/login'
import SchedulingsPage from 'modules/schedulings'

export default function Router () {
  const [, httpClient] = useApi()
  const dispatch = useDispatch()
  const navigate = useHistory()
  const { user } = useSelector(state => state.app)

  useEffect(() => {
    if (user.token) return
    const stored = window?.sessionStorage?.getItem(STORAGE_KEYS.USER)

    if (stored) {
      const userData = JSON.parse(stored)
      if (userData?.token) {
        httpClient
          .setBearerToken(userData.token)
          .get(routes.users.verifyToken, null, { unhandled: true })
          .then(() => dispatch(setUser(userData)))
          .catch(() => {
            dispatch(logoutUser())
            navigate?.push('/')
          })
      }
    } else {
      dispatch(setUserLocalIsChecked(true))
    }
  }, [user])

  if (!user?.localIsChecked) return null

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={PageRoutes.home}>
          <SchedulingsPage />
        </Route>
        <PrivateRoute exact path={PageRoutes.account}>
          <AccountPage />
        </PrivateRoute>
        <PublicRestrictRoute path={PageRoutes.login}>
          <LoginPage />
        </PublicRestrictRoute>
      </Switch>
    </BrowserRouter>
  )
}
