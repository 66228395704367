export default function validateCPF (cpf = '') {
  let sum = 0
  let result

  cpf = cpf.replace(/[\D]/g, '')
  if (cpf === '00000000000') return false

  for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  result = (sum * 10) % 11

  if ((result === 10) || (result === 11)) result = 0
  if (result !== parseInt(cpf.substring(9, 10))) return false

  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  }

  result = (sum * 10) % 11

  if ((result === 10) || (result === 11)) result = 0
  if (result !== parseInt(cpf.substring(10, 11))) return false
  return true
}
