import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import defaultAvatar from 'assets/images/avatar.png'
import MuiAlertDialog from 'components/MuiAlertDialog'
import { Avatar, BookCard } from 'components/Styled'
import useApi from 'common/hooks/useApi'
import { SCHEDULER_STEPS } from '../constants'
import { ProfessionalCard, ProfessionalInfo, StepTitle } from './styled'
import { setDate } from '../schedulings.store'
import routes from 'services/api/routes'
import { setProfessionalBook } from 'modules/app/appSlice'

const DIALOG_INITIAL_DATA = {
  open: false,
  title: '',
  message: '',
  value: null
}

function DateStep ({
  onNextStep: handleNextStep,
  title
}) {
  const [isLoading, httpClient] = useApi()
  const dispatch = useDispatch()
  const date = useSelector(state => state.schedulings[SCHEDULER_STEPS.Date.StepNumber])
  const { professionals, professinalBookOptionsFetched } = useSelector(state => state.app)
  const professional = useSelector(state => state.schedulings[SCHEDULER_STEPS.Professional.StepNumber])
  const [dialog, setDialog] = useState(DIALOG_INITIAL_DATA)
  const professionalIndex = professionals.findIndex(item => item.id === professional?.id)
  const { bookOptions = [] } = professionals?.[professionalIndex] ?? {}
  const bookOptionsFetched = !isLoading && professinalBookOptionsFetched === true

  const handleConfirmed = (dateAndTime) => {
    dispatch(setDate(dateAndTime))
    handleNextStep()
  }

  const handleSelectedHour = (date, day, hour) => {
    setDialog({
      open: true,
      title: 'Confirmação',
      message: `Você selecionou ${date} às ${hour}. Deseja confirmar e continuar?`,
      value: { day, hour }
    })
  }

  const handleTimeClass = (dateSpot, hourSpot) => {
    return dateSpot === date.day && hourSpot === date.hour
      ? 'selected'
      : ''
  }

  const renderBookOptions = (bookOptions) => {
    return bookOptions.map(item => (
      <BookCard key={item.date}>
        <div className='book-heading'>{item.date}</div>
        <div className='book-body'>
          {item.hour?.map(({ value: hour }) =>
            <span
              key={hour}
              onClick={() => handleSelectedHour(item.date, item.originalDate, hour)}
              className={handleTimeClass(item.originalDate, hour)}
            >
              {hour}
            </span>
          )}
        </div>
      </BookCard>
    ))
  }

  // didMount
  useEffect(() => {
    if (!date?.day && professional?.bookOptions?.length) {
      dispatch(setDate({
        day: professional?.bookOptions[0]?.originalDate?.substr(0, 10),
        hour: null
      }))
    }

    if (bookOptions.length === 0) {
      // request from API
      httpClient
        .get(routes.professionals.getAvailability(professional.id))
        .then(data => dispatch(setProfessionalBook({ id: professional.id, data })))
    }
  }, [])

  return (
    <div>
      <StepTitle>{title}</StepTitle>
      <ProfessionalCard>
        <div className='info'>
          <Avatar alt={professional?.name} size={70} src={professional?.avatar || defaultAvatar} />
          <ProfessionalInfo>
            <h4>Dr. {professional?.name}</h4>
          </ProfessionalInfo>
        </div>
        <div className='book'>
          {isLoading && <p>Carregando horários disponíveis...</p>}
          {bookOptionsFetched && bookOptions.length === 0
            ? <p>Não há horários disponíveis no período selecionado.</p>
            : renderBookOptions(bookOptions)}
        </div>
      </ProfessionalCard>

      <MuiAlertDialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onCancel={() => setDialog(DIALOG_INITIAL_DATA)}
        onConfirm={() => handleConfirmed(dialog.value)}
        labelConfirm='Confirmar'
      />
    </div>
  )
}

DateStep.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

export default DateStep
