import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Divider, IconButton } from '@material-ui/core'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import useApi from 'common/hooks/useApi'
import { logoutUser } from 'modules/app/appSlice'
import { PageRoutes } from 'modules/app/Router/routes'
import PageFooter from './Footer'
import {
  Header,
  Logo,
  PageTitle,
  PageWrapper,
  SignInButton,
  Topbar,
  UserBox,
  UserMenu
} from './styled'

function MainLayout ({ children, title }) {
  const [, httpClient] = useApi()
  const dispatch = useDispatch()
  const navigate = useHistory()
  const { user } = useSelector(state => state.app)
  const isAuthenticated = user?.name !== null

  const handleSignOut = () => dispatch(logoutUser())

  const handleUserMenuClick = () => {
    if (isAuthenticated) navigate.push(PageRoutes.account)
  }

  useEffect(() => {
    if (isAuthenticated) return
    httpClient.clearBearerToken()
  }, [isAuthenticated])

  return (
    <PageWrapper>
      <Topbar>
        <Container>
          <div className='content'>
            <Link to='/'>
              <Logo />
            </Link>
            <Divider orientation='vertical' />
            <Link to='/'>
              <span>FVG AGENDAMENTO ON-LINE</span>
            </Link>
          </div>
          <div className='content'>
            {!user?.name
              ? (
                <SignInButton data-testid='signin-button' onClick={() => navigate.push('/login')}>
                  ENTRE OU CADASTRE-SE
                </SignInButton>
                )
              : (
                <UserBox>
                  <UserMenu onClick={handleUserMenuClick}>
                    <AccountCircleOutlinedIcon htmlColor='white' />
                    {user.name}
                  </UserMenu>
                  <Divider orientation='vertical' />
                  <IconButton size='small' onClick={handleSignOut}>
                    <ExitToAppOutlinedIcon htmlColor='white' />
                  </IconButton>
                </UserBox>
                )}
          </div>
        </Container>
      </Topbar>
      <Header>
        <div className='bg-overlay'>
          <Container>
            {title || <PageTitle />}
          </Container>
        </div>
      </Header>

      <div className='app-wrapper'>
        {children}
      </div>

      <PageFooter />
    </PageWrapper>
  )
}

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.element
}

export default MainLayout
