import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetNavigateTo } from '../appSlice'

function PublicRestrictRoute ({ children, ...rest }) {
  const { navigateTo, user } = useSelector(state => state.app)
  const dispatch = useDispatch()
  const navigate = useHistory()
  const isLoggedIn = !!user?.token

  useEffect(() => {
    if (!navigateTo) return
    if (isLoggedIn) {
      navigate.push(navigateTo)
      dispatch(resetNavigateTo())
    }
  }, [navigateTo])

  if (isLoggedIn) return null

  return (
    <Route {...rest}>
      {children}
    </Route>
  )
}

PublicRestrictRoute.propTypes = {
  children: PropTypes.element.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired
}

export default PublicRestrictRoute
