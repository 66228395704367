import { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Box, Grid } from '@material-ui/core'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import { AiOutlineGoogle, AiFillFacebook } from 'react-icons/ai'
import useApi from 'common/hooks/useApi'
import SocialButton from 'components/SocialButton'
import { setUser as appSetUser, setNavigateTo } from 'modules/app/appSlice'
import { setUser as schedulingsSetUser } from 'modules/schedulings/schedulings.store'
import routes from 'services/api/routes'
import Form from './components/LoginForm'
import { SocialButtonsWrapper } from './styled'

const { REACT_APP_USE_LOGIN_FACEBOOK, REACT_APP_USE_LOGIN_GOOGLE } = process.env

function Login ({ navigateTo }) {
  const dispatch = useDispatch()
  const [, httpClient] = useApi()
  const [facebookLoginDisabled, setFacebookLoginDisabled] = useState(false)

  const handleFacebookLoginState = () => setFacebookLoginDisabled(state => !state)

  const handleFacebookLoginResponse = async response => {
    const accessToken = response?.accessToken

    if (accessToken) {
      const params = { access_token: accessToken }
      const route = routes.authentication.authWithFacebook
      const user = await httpClient.get(route, { params })

      if (user?.token) {
        httpClient.setBearerToken(user.token)
        dispatch(appSetUser(user))
        dispatch(schedulingsSetUser(user.name))

        if (navigateTo) {
          dispatch(setNavigateTo(navigateTo))
        }
      }
    }

    handleFacebookLoginState()
  }

  const handleGoogleResponse = async response => {
    const token = response?.getAuthResponse?.()?.id_token

    if (token) {
      const route = routes.authentication.authWithGoogle
      const user = await httpClient.post(route, { token })

      if (user?.token) {
        httpClient.setBearerToken(user.token)
        dispatch(appSetUser(user))
        dispatch(schedulingsSetUser(user.name))

        if (navigateTo) {
          dispatch(setNavigateTo(navigateTo))
        }
      }
    }
  }

  return (
    <>
      <Box mb={4}>
        <SocialButtonsWrapper container spacing={2}>
          {REACT_APP_USE_LOGIN_GOOGLE === 'true' && (
            <Grid item md xs={12}>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_OAUTH2_CLIENTID}
                buttonText='Entrar com Google'
                onSuccess={handleGoogleResponse}
                onFailure={handleGoogleResponse}
                cookiePolicy='single_host_origin'
                render={({ disabled, onClick }) => (
                  <SocialButton brand='google' onClick={onClick} disabled={disabled}>
                    <AiOutlineGoogle /> Entrar com Google
                  </SocialButton>
                )}
              />
            </Grid>
          )}
          {REACT_APP_USE_LOGIN_FACEBOOK === 'true' && (
            <Grid item xs>
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APPID}
                fields='name,email,picture'
                onClick={handleFacebookLoginState}
                callback={handleFacebookLoginResponse}
                render={({ onClick }) => (
                  <SocialButton brand='facebook' onClick={onClick} disabled={facebookLoginDisabled}>
                    <AiFillFacebook /> Entrar com Facebook
                  </SocialButton>
                )}
              />
            </Grid>
          )}
        </SocialButtonsWrapper>
      </Box>

      <Form httpClient={httpClient} navigateTo={navigateTo} />
    </>
  )
}

Login.propTypes = {
  navigateTo: PropTypes.string
}

export default Login
