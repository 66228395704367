import { REQUIRED } from 'common/messages'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  cvc: Yup.string().required(REQUIRED).length(3, 'Informe o código de segurança.'),
  expiry: Yup
    .string()
    .required(REQUIRED)
    .length(7, 'Informe a data de expiração.')
    .test(
      'is-valid-expiration-date',
      'Data de expiração inválida.',
      value => {
        const parts = value.split('/')
        if (parts.length !== 2) return false
        const month = Number(parts[0])
        const year = Number(parts[1])
        const currentMonth = new Date().getMonth()
        const currentYear = new Date().getFullYear()
        if (!/^(0[1-9]|1[0-2]){1,2}$/.test(parts[0])) return false
        if (year === currentYear && month <= currentMonth) return false
        if (year < currentYear || year > currentYear + 10) return false
        return true
      }
    ),
  installments: Yup.string().required(REQUIRED).max(2, 'Selecione.'),
  name: Yup.string().required(REQUIRED),
  number: Yup.string().required(REQUIRED)
})
