import { Button as MuiButton } from '@material-ui/core'
import styled from 'styled-components'

export const Button = styled(MuiButton)`
  padding: 12px 16px !important;
  text-align: center;

  .MuiButton-label {
    text-transform: lowercase;
  }

  svg {
    position: absolute;
    left: 12px;
    font-size: 20px;
  }
`
