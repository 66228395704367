import { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField } from '@material-ui/core'
import useApi from 'common/hooks/useApi'
import { StyledGrid, StyledLabel } from 'components/Styled'
import { ConfirmButton } from './styled'
import routes from 'services/api/routes'

function AccountCreated ({ email }) {
  const [isLoading, httpClient] = useApi()
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [validated, setValidated] = useState(false)

  const handleChange = ({ target: { value } }) => {
    if (value.length > 6) return
    setCode(value.toUpperCase())
  }

  const handleValidate = async () => {
    const request = await httpClient.post(routes.users.validateRegistration, { code, email })
    if (request?.validated) setValidated(true)
    else setError('Código inválido.')
  }

  if (validated) {
    return (
      <Grid container spacing={2}>
        <StyledGrid item mb={20} xs={12}>
          <p>
            <strong>Muito bem!</strong> Seu cadastro está ativo. Por favor realize o login para continuar.
          </p>
        </StyledGrid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={2}>
      <StyledGrid item mb={20} xs={12}>
        <p>
          O próximo passo é validar o seu cadastro informando abaixo o código que nós enviamos para <strong>{email}</strong>.
        </p>
        <p>
          Se não encontrar a mensagem, verifique sua caixa de SPAM.
        </p>
      </StyledGrid>
      <Grid item md={5} xs={12}>
        <StyledLabel>
          Código
        </StyledLabel>
        <StyledGrid flex item xs alignItems='center'>
          <TextField
            required
            variant='outlined'
            name='registration-code'
            onChange={handleChange}
            value={code}
            inputProps={{
              'data-testid': 'input-registration-code'
            }}
          />
          <ConfirmButton disabled={isLoading} onClick={handleValidate}>
            VALIDAR
          </ConfirmButton>
        </StyledGrid>
        {error?.length > 0 && <p className='red'>{error}</p>}
      </Grid>
    </Grid>
  )
}

AccountCreated.propTypes = {
  email: PropTypes.string.isRequired
}

export default AccountCreated
