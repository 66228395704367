import { memo } from 'react'
import { Grid, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { InputError, StyledLabel } from 'components/Styled'

function InputText ({
  error,
  errorStyle,
  label,
  onChange,
  required,
  testId,
  variant,
  ...rest
}) {
  const inputVariant = variant || 'outlined'
  const inputProps = { ...rest?.inputProps, 'data-testid': testId }

  return (
    <>
      {label && (
        <StyledLabel>
          {label} {required && <span className='red'>*</span>}
        </StyledLabel>
      )}
      <Grid item xs={12} style={{ position: 'relative' }}>
        <TextField
          autoComplete='off'
          onChange={onChange}
          variant={inputVariant}
          inputProps={inputProps}
          {...rest}
        />
        {error && <InputError data-testid={`input-${rest?.name}-error`} errorStyle={errorStyle}>{error}</InputError>}
      </Grid>
    </>
  )
}

InputText.propTypes = {
  error: PropTypes.string,
  errorStyle: PropTypes.oneOf(['default', 'absolute']),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  testId: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  value: PropTypes.string
}

export default memo(InputText)
