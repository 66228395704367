import { REQUIRED } from 'common/messages'
import Yup from 'common/yupValidators'

const INVALID_BIRTHDATE = 'Data de nascimento inválida.'
const INVALID_DOC = 'Número de CPF inválido.'
const INVALID_PHONE = 'Número de telefone inválido.'

export const validationSchema = (missingInfo) => Yup.object().shape({
  birthdate: missingInfo?.birthdate
    ? Yup.string().required(REQUIRED).validateBirthdate(INVALID_BIRTHDATE)
    : Yup.string(),
  document: missingInfo?.document
    ? Yup.string().required(REQUIRED).validateCPF(INVALID_DOC)
    : Yup.string(),
  phone: missingInfo?.phoneNumber
    ? Yup.string().required(REQUIRED).validatePhone(INVALID_PHONE)
    : Yup.string()
})
