import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import accountReducer from 'modules/account/accountSlice'
import appReducer from 'modules/app/appSlice'
// import { specialtiesRepository } from 'modules/schedulings/specialties.query'
import schedulingsReducer from 'modules/schedulings/schedulings.store'

export const store = configureStore({
  reducer: {
    account: accountReducer,
    app: appReducer,
    schedulings: schedulingsReducer
    // [specialtiesRepository.reducerPath]: specialtiesRepository.reducer
  }
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(schedulingsQuery.middleware)
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
