import { REQUIRED } from 'common/messages'
import Yup from 'common/yupValidators'

const PASSW_MAX_LENGTH = 'Informe uma senha com no máximo 12 caracteres.'
const PASSW_MIN_LENGTH = 'Informe uma senha com no mínimo 6 caracteres.'

export const validationSchema = Yup.object()
  .shape({
    birthdate: Yup.string()
      .required(REQUIRED)
      .validateBirthdate('Data de nascimento inválida.'),
    document: Yup.string()
      .required(REQUIRED)
      .validateCPF('Número de CPF inválido.'),
    email: Yup.string()
      .required(REQUIRED)
      .email('Endereço de e-mail inválido.'),
    phone: Yup.string()
      .required(REQUIRED)
      .validatePhone('Número de telefone inválido.'),
    name: Yup.string()
      .required(REQUIRED),
    password: Yup.string()
      .required(REQUIRED)
      .min(6, PASSW_MIN_LENGTH)
      .max(12, PASSW_MAX_LENGTH),
    passwordConfirmation: Yup.string()
      .required(REQUIRED)
      .min(6, PASSW_MIN_LENGTH)
      .max(12, PASSW_MAX_LENGTH)
      .oneOf([Yup.ref('password')], 'A confirmação de senha falhou.')
  })
