import { Container, Grid, Typography } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { FooterContainer, FooterLinks, LogoFooter, SocialBox } from './styled'

export default function Footer () {
  return (
    <FooterContainer>
      <Container>
        <Grid container>
          <Grid item md={3} xs={12}>
            <LogoFooter />
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography variant='h6'>
              Institucional
            </Typography>
            <FooterLinks>
              <li>Quem somos</li>
              <li>Parcerias</li>
              <li>FVG Social</li>
              <li>Hospital de Cirurgia</li>
            </FooterLinks>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography variant='h6'>
              Saiba mais
            </Typography>
            <FooterLinks>
              <li>Blog</li>
              <li>Carreiras</li>
              <li>Seja um médico do nosso time</li>
              <li>Contato</li>
            </FooterLinks>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography variant='h6'>
              Siga nossas redes
            </Typography>
            <SocialBox>
              <FacebookIcon />
              <TwitterIcon />
              <InstagramIcon />
              <LinkedInIcon />
            </SocialBox>
          </Grid>
        </Grid>
      </Container>
    </FooterContainer>
  )
}
