import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'

function MuiAlertDialog ({
  children,
  open = false,
  onCancel: handleCancel,
  onConfirm: handleConfirm,
  labelCancel,
  labelConfirm,
  message,
  title,
  ...props
}) {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        {labelCancel?.length > 0 && (
          <Button onClick={handleCancel} color="primary">
            {labelCancel ?? 'Cancelar'}
          </Button>
        )}
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {labelConfirm ?? 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MuiAlertDialog.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  labelCancel: PropTypes.string,
  labelConfirm: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default MuiAlertDialog
