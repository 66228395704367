import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Cards from 'react-credit-cards'
import { Button, CardContent, Grid, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import pagarme from 'pagarme'
import { useDispatch, useSelector } from 'react-redux'

import useApi from 'common/hooks/useApi'
import { StyledGrid } from 'components/Styled'
import { setAppointmentPrice } from 'modules/app/appSlice'
import { SCHEDULER_STEPS_INDEX } from 'modules/schedulings/constants'
import { setCheckout } from 'modules/schedulings/schedulings.store'
import routes from 'services/api/routes'

import CreditCardForm from './components/CreditCardForm'
import { validationSchema } from './validationSchema'
import { CheckoutCard } from './styled'
import { numberFormat } from 'common/utils'
import { PAYMENT_ERRORS } from './constants'

function Payment ({ missingData }) {
  const [requestLoading, httpClient] = useApi()
  const dispatch = useDispatch()
  const [cardFocus, setCardFocus] = useState('')
  const [error, setError] = useState(null)

  const date = useSelector(state => state.schedulings[SCHEDULER_STEPS_INDEX.DATE])
  const modality = useSelector(state => state.schedulings[SCHEDULER_STEPS_INDEX.MODALITY])
  const professional = useSelector(state => state.schedulings[SCHEDULER_STEPS_INDEX.PROFESSIONAL])
  const { appointmentPrice } = useSelector(state => state.app)
  const price = !appointmentPrice?.fetched ? 0 : appointmentPrice?.value
  const formattedPrice = price !== 0
    ? `R$ ${numberFormat(appointmentPrice?.value, 2, ',', '.')}`
    : 'R$ 999,99'

  const { submitForm, ...formik } = useFormik({
    initialValues: {
      cvc: '',
      expiry: '',
      installments: 1,
      name: '',
      number: ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      // const card = {
      //   card_number: '4111111111111111',
      //   card_holder_name: 'abc',
      //   card_expiration_date: '1225',
      //   card_cvv: '123'
      // }
      const card = {
        card_number: values.number,
        card_holder_name: values.name,
        card_expiration_date: values.expiry?.replace(/[\D]/gi, ''),
        card_cvv: values.cvc
      }

      // generate card hash from Payment Gateway
      const cardHash = await pagarme.client
        .connect({ encryption_key: process.env.REACT_APP_PAGARME_KEY })
        .then(client => client.security.encrypt(card))

      const payload = {
        cardHash,
        date: `${date?.day?.substr(0, 10)}T${date?.hour}:00.000Z`,
        modality: modality === 'PRESENCIAL' ? 'presential' : 'online',
        professionalId: professional.id,
        paymentInstallments: values.installments,
        ...missingData
      }

      // register checkout
      httpClient
        .post(routes.checkout.create, payload, { unhandled: true })
        .then(data => {
          if (data?.transaction && data?.status === 'paid') {
            dispatch(setCheckout({
              transactionId: data.transaction,
              transactionValue: formattedPrice
            }))
          }
        })
        .catch(err => {
          const error = err?.response?.data?.message || err.message || PAYMENT_ERRORS.GENERAL_ERROR
          const status = err?.response?.data?.status || PAYMENT_ERRORS.GENERAL_ERROR
          if (error === 'book-spot-not-available') {
            setError(PAYMENT_ERRORS.BOOK_SPOT_NOT_AVAILABLE)
          } else {
            console.log(err?.response?.data)
            console.error(error)
            if (status === 'unavailable') {
              setError(PAYMENT_ERRORS.GENERAL_ERROR)
            } else if (status === 'refused') {
              setError(PAYMENT_ERRORS.REFUSED_ERROR)
            } else {
              setError(error)
            }
          }
        })
    }
  })

  const handleInputFocus = ({ target: { name } }) => {
    setCardFocus(name)
  }

  useEffect(() => {
    httpClient
      .get(routes.appointments.getPrice(professional.id))
      .then(({ price: value }) => dispatch(setAppointmentPrice({ fetched: true, value })))
      .catch(err => console.error(err))
  }, [])

  if (!price) return null

  return (
    <StyledGrid container mt={24} spacing={1}>
      <StyledGrid item mt={12} xs={12}>
        <CheckoutCard variant='outlined'>
          <CardContent className='heading'>
            <Typography variant='body1'>
              DETALHES DO PAGAMENTO
            </Typography>
          </CardContent>
          <CardContent className='items'>
            <StyledGrid container item bottomline='1px solid #D2D8DE' py={12} xs={12}>
              <Grid item md={10} xs={6}>
                CONSULTA {modality}
              </Grid>
              <Grid item className='price' md={2} xs={6}>
                {formattedPrice}
              </Grid>
            </StyledGrid>

            <StyledGrid container item mb={14} py={12} xs={12}>
              <Grid item md={10} className='total' xs={6}>
                TOTAL:
              </Grid>
              <Grid item md={2} className='total price' xs={6}>
                {formattedPrice}
              </Grid>
            </StyledGrid>

            <StyledGrid container item mb={24} xs={12}>
              <Grid item md={6} xs={12}>
                <Cards
                  {...formik.values}
                  focused={cardFocus}
                  locale={{ valid: 'VENCIMENTO' }}
                  placeholders={{
                    name: 'SEU NOME',
                    expiration: 'VENCIMENTO'
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CreditCardForm
                  formik={formik}
                  onFocus={handleInputFocus}
                  price={String(price)}
                />
              </Grid>
            </StyledGrid>

            {error && <p className='red'>{error}</p>}

            <Button
              fullWidth
              disabled={requestLoading}
              color='primary'
              variant='contained'
              onClick={formik.handleSubmit}
            >
              PAGAR {formattedPrice}
            </Button>
            {/* <Typography variant='body2' className='footer'>
              Você pode parcelar em até 10x sem juros no cartão de crédito.
            </Typography> */}
          </CardContent>
        </CheckoutCard>
      </StyledGrid>
    </StyledGrid>
  )
}

Payment.propTypes = {
  missingData: PropTypes.object.isRequired
}

export default Payment
