import { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import defaultAvatar from 'assets/images/avatar.png'
import MuiAlertDialog from 'components/MuiAlertDialog'
import useApi from 'common/hooks/useApi'
import getTzDate from 'common/utils/getTzDate'
import useCalendarNavigation from 'common/hooks/useCalendarNavigation'
import {
  Avatar,
  BookCard,
  Calendar,
  SpacedTextButton,
  StyledBox,
  StyledButton,
  StyledGrid,
  ThreeDotsLoader
} from 'components/Styled'
import { differenceInDays, format } from 'date-fns'
import routes from 'services/api/routes'
import { APPOINTMENT_STATUS, BookStatus } from './constants'
import { AppointmentCard, AppointmentStatus } from './styled'

const DIALOG_INITIAL_DATA = {
  open: false,
  title: '',
  message: '',
  value: null
}

function Appointment ({ data, onRefreshList, user }) {
  const [isLoading, httpClient] = useApi()
  const [bookOptions, setBookOptions] = useState([])
  const [dateChangeOpen, setDateChangeOpen] = useState(false)
  const [calendarDate, setCalendarDate] = useState(data?.scheduledAt?.slice(0, 10))
  const [dialog, setDialog] = useState(DIALOG_INITIAL_DATA)
  const [appointmentSpot, setAppointmentSpot] = useState({
    id: null,
    day: data?.scheduledAt?.slice(0, 10) ?? null,
    hour: data?.scheduledAt ? format(getTzDate(new Date(data.scheduledAt)), 'HH:mm') : null,
    isBlockedForChanges: data?.scheduledAt
      ? differenceInDays(getTzDate(new Date(data.scheduledAt)), new Date()) <= 1
      : false
  })

  const appointmentDateChanged =
    `${appointmentSpot?.day}T${appointmentSpot?.hour}` !== data.scheduledAt.slice(0, 16)

  const isActionButtonsVisible =
    data.status !== APPOINTMENT_STATUS.CANCELED &&
    appointmentSpot?.isBlockedForChanges !== true

  const minDate = format(new Date(), 'yyyy-MM-dd')

  const disabledDateFilter = useCallback((date) => {
    const availableDates = bookOptions
      ?.map(item => item.originalDate.slice(0, 10)) || []

    return !availableDates.includes(date)
  }, [bookOptions])

  const handleCancelSelectedSpot = () => {
    setAppointmentSpot({
      id: null,
      day: data?.scheduledAt?.slice(0, 10) ?? null,
      hour: data?.scheduledAt ? format(getTzDate(new Date(data.scheduledAt)), 'HH:mm') : null
    })
    setDialog(DIALOG_INITIAL_DATA)
  }

  const handleConfirmCancelation = async () => {
    const response = await httpClient
      .get(routes.appointments.cancel(data.id))

    if (response?.canceled) onRefreshList()
  }

  const handleConfirmSelectedSpot = async () => {
    setDialog(DIALOG_INITIAL_DATA)

    const payload = {
      spotId: appointmentSpot.id,
      date: `${appointmentSpot?.day}T${appointmentSpot?.hour}:00.000Z`
    }

    const updated = await httpClient
      .post(
        routes.appointments.reschedule(data.id), payload, { unhandled: true }
      )
      .catch(err => console.error(err.message))

    if (updated?.success) {
      setDateChangeOpen(false)
      onRefreshList && onRefreshList()
    }
  }

  const handleDateSelected = useCallback(({ iso }) => {
    setCalendarDate(iso.slice(0, 10))
  }, [])

  const handleSpotSelected = (item, spot) => {
    setAppointmentSpot(spot)
    setDialog({
      open: true,
      title: 'Confirmação',
      message: `Você selecionou ${item.date} às ${spot.hour}. Deseja confirmar e continuar?`
    })
  }

  const handleTimeClass = (dateSpot, hourSpot) => {
    return dateSpot?.slice(0, 10) === appointmentSpot?.day &&
      hourSpot === appointmentSpot?.hour
      ? 'selected'
      : ''
  }

  const getDate = scheduledAt => {
    const date = new Date(scheduledAt)
    return format(getTzDate(date), "dd/MM/yyyy 'às' HH'h'mm")
  }

  // didUpdate: fetch availability spots for selected professional
  useEffect(() => {
    if (!dateChangeOpen) {
      setCalendarDate(data?.scheduledAt?.slice(0, 10))
      return
    }

    const professional = data?.professional
    if (!professional?.id) return

    httpClient
      .get(routes.professionals.getAvailability(professional.id))
      .then(setBookOptions)
  }, [dateChangeOpen])

  // didUpdate: update calendar date once appointment spot day is changed
  useEffect(() => {
    setCalendarDate(appointmentSpot?.day)
  }, [appointmentSpot?.day])

  // custom hook to handle calendar navigation listeners
  // and fetch the professional's book availability options
  useCalendarNavigation(null, data?.professional?.id, setBookOptions)

  return (
    <AppointmentCard>
      <Avatar
        alt={data?.professional?.name}
        ml={10}
        mr={25}
        size={160}
        src={data?.professionalAvatar || defaultAvatar}
        className='avatar'
      />

      <Grid item xs>
        <Typography variant='h6'>
          <strong>CONSULTA COM DR(A) {data.professional?.name}</strong>
        </Typography>
        <AppointmentStatus status={data.status}>
          <CheckIcon fontSize='small' />
          {BookStatus?.[data.status]?.text || 'Não informado'}
        </AppointmentStatus>

        <Typography variant='body1'>
          <strong>Data:</strong> {getDate(data.scheduledAt)}
        </Typography>
        <Typography variant='body1'>
          <strong>Paciente:</strong> {user.name}
        </Typography>
        <Typography variant='body1'>
          <strong>Clínica(s):</strong> {data?.clinics?.join(', ')}
        </Typography>
      </Grid>

      {isActionButtonsVisible && (
        <StyledBox width={200} ml={10} className='action-buttons'>
          <SpacedTextButton
            fullWidth
            disabled={dateChangeOpen}
            color='primary'
            variant='outlined'
            mb={10}
            onClick={() => setDateChangeOpen(true)}
          >
            ALTERAR DATA
          </SpacedTextButton>
          <SpacedTextButton
            fullWidth
            color='secondary'
            variant='outlined'
            onClick={handleConfirmCancelation}
          >
            DESMARCAR
          </SpacedTextButton>
        </StyledBox>
      )}

      {isLoading && (
        <Grid container justifyContent='center'>
          <ThreeDotsLoader medium my={15} />
        </Grid>
      )}

      {!isLoading && dateChangeOpen && (
        <StyledGrid container item xs={12} mt={20}>
          <Grid item md={3} xs={12}>
            <Calendar
              day={0}
              disabledDateFilter={disabledDateFilter}
              locale='pt-BR'
              minDate={minDate}
              onSelect={handleDateSelected}
              selected={calendarDate}
            />
          </Grid>
          <Grid item md={9} xs={12}>
            {bookOptions.map(item => (
              <BookCard key={item.date}>
                <div className='book-heading'>{item.date}</div>
                <div className='book-body'>
                  {item.hour?.map(({ id, value: hour }) =>
                    <span
                      key={hour}
                      onClick={() => handleSpotSelected(item, {
                        id,
                        day: item.originalDate?.slice(0, 10),
                        hour
                      })}
                      className={handleTimeClass(item.originalDate, hour)}
                    >
                      {hour}
                    </span>
                  )}
                </div>
              </BookCard>
            ))}

            <StyledGrid container item mt={15} justifyContent='flex-end'>
              <StyledButton
                disabled={!appointmentDateChanged || isLoading}
                color='primary'
                variant='contained'
                mr={15}
                onClick={handleConfirmSelectedSpot}
              >
                Confirmar alteração
              </StyledButton>

              <Button
                disabled={isLoading}
                color='secondary'
                variant='outlined'
                onClick={() => setDateChangeOpen(false)}
              >
                Cancelar
              </Button>
            </StyledGrid>
          </Grid>
        </StyledGrid>
      )}

      {dialog.open && (
        <MuiAlertDialog
          open
          title={dialog.title}
          message={dialog.message}
          onCancel={handleCancelSelectedSpot}
          onConfirm={handleConfirmSelectedSpot}
          labelConfirm='Confirmar'
          labelCancel='Cancelar'
        />
      )}
    </AppointmentCard>
  )
}

Appointment.propTypes = {
  data: PropTypes.object.isRequired,
  onRefreshList: PropTypes.func,
  user: PropTypes.object.isRequired
}

export default memo(Appointment)
