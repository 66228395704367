import styled from 'styled-components'
import { notMobile } from 'theme/responsive'

export const ProfessionalInfo = styled.div`
  color: #75787B;
  font-size: 1.2em;
  margin-bottom: 5px;
  padding: 15px 0;

  .description {
    font-size: 12px;
  }
`

export const ProfessionalCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: .2s;
  ${notMobile} {
    padding: 10px;
  }

  .info {
    display: flex;
    align-items: center;

    .MuiAvatar-root {
      margin-right: 16px;
    }
  }
`

export const ProfessionalsList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;

  li {
    cursor: pointer;
    display: flex;

    &:hover {
      background-color: #e0e0e0;
    }

    &.selected {
      background-color: #d0d0d0;
    }
  }
`

export const OptionButton = styled.span`
  align-items: center;
  border: solid 1px ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  justify-content: center;
  height: 180px;
  width: 100%;
  text-transform: uppercase;
  transition: .2s;

  &.selected,
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`

export const SmallOptionButton = styled.span`
  align-items: center;
  border: solid 1px ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin: 0 12px 12px 0;
  padding: 0 28px;
  height: 48px;
  text-transform: uppercase;
  transition: .1s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  &.selected {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.light};
  }
`

export const StepTitle = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ big }) => big ? '32px' : '24px'};
  font-weight: 300;
  margin-top: 0px;
  ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
`
