import PropTypes from 'prop-types'
import { Grid, MenuItem } from '@material-ui/core'
import InputSelect from 'components/InputSelect'
import InputText from 'components/InputText'
import MaskedCreditCardInput from 'components/MaskedCreditCardInput'
import MaskedShortDateInput from 'components/MaskedShortDateInput'
import { numberFormat } from 'common/utils'
import { CardGrid } from './styled'
import { LIST_QTY_INSTALLMENTS } from '../constants'

function CreditCardForm ({ formik, onFocus, price }) {
  return (
    <CardGrid>
      <Grid item xs={12}>
        <InputText
          fullWidth
          required
          name='number'
          formik={formik}
          placeholder='NÚMERO DO CARTÃO'
          onChange={formik.handleChange}
          onFocus={onFocus}
          value={formik.values.number}
          InputProps={{
            inputComponent: MaskedCreditCardInput,
            'data-testid': 'input-payment-number'
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <InputText
          fullWidth
          required
          name='name'
          formik={formik}
          placeholder='SEU NOME NO CARTÃO'
          onChange={formik.handleChange}
          onFocus={onFocus}
          value={formik.values.name}
          inputProps={{
            'data-testid': 'input-payment-name',
            style: { textTransform: 'uppercase' }
          }}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <InputSelect
          fullWidth
          required
          name='installments'
          formik={formik}
          onChange={formik.handleChange}
          value={String(formik.values.installments)}
          inputProps={{ 'data-testid': 'input-payment-installments' }}
        >
          {LIST_QTY_INSTALLMENTS.map(value => (
              <MenuItem
                key={String(value)}
                value={value}
              >
                {value}x de R$ {numberFormat(price / value, 2, ',')}
              </MenuItem>
          ))}
        </InputSelect>
      </Grid>

      <Grid item md={5} xs={6}>
        <InputText
          fullWidth
          required
          name='expiry'
          formik={formik}
          placeholder='VALIDADE'
          onChange={formik.handleChange}
          onFocus={onFocus}
          value={formik.values.expiry}
          InputProps={{
            inputComponent: MaskedShortDateInput,
            'data-testid': 'input-payment-expiry'
          }}
        />
      </Grid>

      <Grid item md={3} xs={6}>
        <InputText
          fullWidth
          required
          name='cvc'
          formik={formik}
          placeholder='CVC'
          onBlur={() => onFocus({ target: { name: '' } })}
          onChange={formik.handleChange}
          onFocus={onFocus}
          value={formik.values.cvc}
          inputProps={{
            'data-testid': 'input-payment-cvc',
            maxLength: 3
          }}
        />
      </Grid>
    </CardGrid>
  )
}

CreditCardForm.propTypes = {
  formik: PropTypes.object.isRequired,
  onFocus: PropTypes.func.isRequired,
  price: PropTypes.string.isRequired
}

export default CreditCardForm
