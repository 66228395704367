import { createTheme } from '@material-ui/core/styles'
import { colors } from './scopes'

const theme = createTheme({
  // fonts,
  // overrides,
  palette: {
    ...colors,
    // Usado por `getContrastText()` para maximizar o contraste entre
    // o plano de fundo e o texto.
    contrastThreshold: 3,
    // Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
    // dois índices dentro de sua paleta tonal.
    // Por exemplo, mude de Red 500 para Red 300 ou Red 700.
    tonalOffset: 0.2
  },
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        padding: '6px 39px 6px 6px !important'
      }
    },
    MuiButton: {
      outlinedPrimary: {
        borderColor: colors.primary.main
      },
      outlinedSecondary: {
        borderColor: colors.secondary.main
      },
      root: {
        borderRadius: 0
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0
      },
      input: {
        borderRadius: 0,
        padding: '15.5px 14px'
      }
    },
    MuiTypography: {
      h6: {
        fontWeight: 300
      }
    }
  }
})

export default theme
