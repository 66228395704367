import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import DividerWithText from 'components/DividerWithText'
import Login from 'containers/Login'
import Register from 'containers/Register'
import { StepTitle } from '../styled'

function UserStep ({ title }) {
  return (
    <div>
      <StepTitle>{title}</StepTitle>
      <Login />

      <Box mt={2} mb={4}>
        <DividerWithText>OU CADASTRE-SE AGORA:</DividerWithText>
      </Box>

      <Register />
    </div>
  )
}

UserStep.propTypes = {
  title: PropTypes.string.isRequired
}

export default UserStep
