import axios from 'axios'
const apiClient = axios.create({ baseURL: process.env.REACT_APP_BASE_URL })

apiClient.clearBearerToken = function () {
  apiClient.defaults.headers = {
    Accept: 'application/json'
  }

  return this
}

apiClient.setBearerToken = function (token) {
  apiClient.defaults.headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json'
  }

  return this
}

export default apiClient
