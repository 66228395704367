import { Box } from '@material-ui/core'
import MainLayout from 'containers/MainLayout'
import { PageTitle, PageWrapper } from 'components/Styled'
import DividerWithText from 'components/DividerWithText'
import Login from 'containers/Login'
import Register from 'containers/Register'
import { PageRoutes } from 'modules/app/Router/routes'

export default function LoginPage () {
  return (
    <MainLayout>
      <div className='app-box'>
        <PageWrapper>
          <PageTitle bold>Já possui um cadastro? Faça o login:</PageTitle>

          <Login navigateTo={PageRoutes.account} />

          <Box mt={2} mb={4}>
            <DividerWithText>OU CADASTRE-SE AGORA:</DividerWithText>
          </Box>

          <Register />
        </PageWrapper>
      </div>
    </MainLayout>
  )
}
