import PropTypes from 'prop-types'
import MaskedInput from 'react-input-mask'

const MaskedCreditCardInput = ({ inputRef, ...rest }) => {
  return <MaskedInput autoComplete='off' {...rest} ref={inputRef} mask='9999 9999 9999 9999' />
}

MaskedCreditCardInput.propTypes = {
  inputRef: PropTypes.func.isRequired
}

export default MaskedCreditCardInput
