import { useEffect } from 'react'
import { Button, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { PageRoutes } from 'modules/app/Router/routes'
import { resetScheduler } from 'modules/schedulings/schedulings.store'
import { StepTitle } from './styled'

function Confirmed ({ transactionId, transactionValue }) {
  const dispatch = useDispatch()
  const navigate = useHistory()

  /**
   * @didUpdate
   * verify the ending of checkout state
   * and fire conversion event to google analytics
   */
  useEffect(() => {
    if (!transactionId) return

    if (typeof gtag === 'function') {
      // eslint-disable-next-line no-undef
      gtag('event', 'conversion', {
        transaction_id: transactionId,
        value: transactionValue
      })
    }
  }, [transactionId])

  /**
   * @willUnmount
   * resets the scheduling journey state
   */
  useEffect(() => {
    return () => {
      dispatch(resetScheduler())
    }
  }, [])

  return (
    <div>
      <StepTitle>Pagamento confirmado!</StepTitle>
      <Grid container spacing={3}>
        <Grid item xs>
          <p>O ID de sua transação é: {transactionId}.</p>

          <Button
            color="primary"
            onClick={() => navigate.push(PageRoutes.account)}
            variant='contained'
            style={{ marginTop: 40 }}
          >
            Ir para Meus agendamentos
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

Confirmed.propTypes = {
  transactionId: PropTypes.string.isRequired,
  transactionValue: PropTypes.string.isRequired
}

export default Confirmed
