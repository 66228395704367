import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

function PrivateRoute ({ children, ...rest }) {
  const { user } = useSelector(state => state.app)
  const navigate = useHistory()
  const isLoggedIn = !!user?.token

  useEffect(() => {
    if (!isLoggedIn) navigate.push('/')
  }, [isLoggedIn])

  if (!isLoggedIn) return null

  return (
    <Route {...rest}>
      {children}
    </Route>
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired
}

export default PrivateRoute
