const appointments = {
  cancel: id => `/appointments/${id}/cancelation`,
  getPrice: professionalId => `/appointments/price?professionalId=${professionalId}`,
  reschedule: id => `/appointments/${id}/reschedule`
}

const authentication = {
  authWithEmail: '/auth',
  authWithFacebook: '/auth/with-facebook',
  authWithGoogle: '/auth/with-google'
}

const checkout = {
  create: '/checkout'
}

const professionals = {
  getAll: '/professionals',
  getAvailability: id => `/professionals/${id}/availability`
}

const locations = {
  getCities: stateCode => `/locations/cities?state=${stateCode}`
}

const users = {
  create: '/users',
  appointments: '/users/appointments',
  missingInfo: '/users/missing-info',
  passwordRecovery: '/users/password-recovery',
  passwordRecoveryValidation: '/users/password-recovery-validation',
  validateRegistration: '/users/validate-registration',
  verifyToken: '/users/verify-token'
}

const routes = {
  appointments,
  authentication,
  checkout,
  locations,
  professionals,
  users
}

export default routes
