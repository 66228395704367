import { createSlice } from '@reduxjs/toolkit'
import { STORAGE_KEYS } from './constants'

const initialState = {
  appointmentPrice: {
    fetched: false,
    value: 400 // fallback
  },
  navigateTo: null,
  professionals: [],
  professinalBookOptionsFetched: false,
  region: 'MG',
  user: {
    missingInfo: {
      billingAddress: false,
      birthdate: false,
      document: false,
      phoneNumber: false
    },
    name: null,
    localIsChecked: false,
    token: null
  }
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    logoutUser: (state, action) => {
      state.user = {
        ...initialState.user,
        localIsChecked: true
      }
      const stored = window?.sessionStorage?.getItem(STORAGE_KEYS.USER)
      if (stored) window.sessionStorage.removeItem(STORAGE_KEYS.USER)
    },
    resetNavigateTo: (state, action) => {
      state.navigateTo = null
    },
    setAppointmentPrice: (state, action) => {
      state.appointmentPrice = action.payload
    },
    setNavigateTo: (state, action) => {
      state.navigateTo = action.payload
    },
    setProfessionals: (state, action) => {
      state.professionals = action.payload
    },
    setProfessionalBook: (state, action) => {
      const professionalId = action?.payload?.id
      const index = state.professionals.findIndex(professional => professional.id === professionalId)

      if (state.professionals[index]) {
        state.professionals[index].bookOptions = action?.payload?.data
        state.professinalBookOptionsFetched = true
      }
    },
    setRegion: (state, action) => {
      state.region = action.payload
    },
    setUser: (state, action) => {
      state.user = {
        ...action.payload,
        localIsChecked: true
      }
      window?.sessionStorage?.setItem(
        STORAGE_KEYS.USER,
        JSON.stringify(action.payload)
      )
    },
    setUserLocalIsChecked: (state, action) => {
      state.user.localIsChecked = action.payload
    },
    setUserMissingInfo: (state, action) => {
      state.user.missingInfo = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  logoutUser,
  resetNavigateTo,
  setAppointmentPrice,
  setNavigateTo,
  setProfessionals,
  setProfessionalBook,
  setRegion,
  setUser,
  setUserLocalIsChecked,
  setUserMissingInfo
} = appSlice.actions

export default appSlice.reducer
