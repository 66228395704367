import { Button, Grid } from '@material-ui/core'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { internationalizeDate } from 'common/utils'
import InputText from 'components/InputText'
import MaskedCpfInput from 'components/MaskedCpfInput'
import MaskedDateInput from 'components/MaskedDateInput'
import MaskedPhoneInput from 'components/MaskedPhoneInput'
import { StyledGrid } from 'components/Styled'
import { validationSchema } from './validationSchema'

function Info ({ missingUserInfo, onSubmit }) {
  const { errors, handleChange, submitForm, values } = useFormik({
    initialValues: {
      birthdate: '',
      document: '',
      phone: ''
    },
    onSubmit: values => {
      const data = {}

      if (missingUserInfo?.document) {
        data.birthdate = internationalizeDate(values.birthdate)
      }

      if (missingUserInfo?.document) {
        data.document = values.document.replace(/[\D]/g, '')
      }
      if (missingUserInfo?.phoneNumber) {
        data.phone = `+55${values.phone.replace(/[\D]/g, '')}`
      }

      onSubmit('info', data)
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: validationSchema(missingUserInfo)
  })

  return (
    <StyledGrid container spacing={2} mt={25}>
      <StyledGrid container item xs={12} mb={20}>
        Algumas informações são necessárias para processarmos o seu agendamento.
      </StyledGrid>
      {missingUserInfo?.birthdate && (
        <Grid item mb={20} md={4} xs={12}>
          <InputText
            fullWidth
            required
            label='DATA DE NASCIMENTO'
            name='birthdate'
            testId='input-birthdate'
            error={errors.birthdate}
            onChange={handleChange}
            value={values.birthdate}
            InputProps={{
              inputComponent: MaskedDateInput
            }}
          />
        </Grid>
      )}

      {missingUserInfo?.document && (
        <Grid item mb={20} md={4} xs={12}>
          <InputText
            fullWidth
            required
            label='CPF'
            name='document'
            testId='input-document'
            error={errors.document}
            onChange={handleChange}
            value={values.document}
            InputProps={{
              inputComponent: MaskedCpfInput
            }}
          />
        </Grid>
      )}

      {missingUserInfo?.phoneNumber && (
        <Grid item mb={20} md={4} xs={12}>
          <InputText
            fullWidth
            required
            label='TELEFONE'
            name='phone'
            testId='input-phone'
            error={errors.phone}
            onChange={handleChange}
            value={values.phone}
            InputProps={{
              inputComponent: MaskedPhoneInput
            }}
          />
        </Grid>
      )}

      <Grid container item justifyContent='flex-end'>
        <Button
          color='primary'
          variant='contained'
          onClick={submitForm}
        >
          CONTINUAR
        </Button>
      </Grid>
    </StyledGrid>
  )
}

Info.propTypes = {
  missingUserInfo: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
}

export default Info
