import PropTypes from 'prop-types'
import MaskedInput from 'react-input-mask'

const MaskedPhoneInput = ({ inputRef, ...rest }) => {
  const mask = rest.value?.length === 14 ? '(99) 9999-99999' : '(99) 99999-9999'
  return <MaskedInput autoComplete='off' {...rest} maskPlaceholder='' ref={inputRef} mask={mask} />
}

MaskedPhoneInput.propTypes = {
  inputRef: PropTypes.func.isRequired
}

export default MaskedPhoneInput
