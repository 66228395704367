import { Box, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { mobile } from 'theme/responsive'

export const Footer = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'flex-end'
})`
  width: 100%;
  max-width: 1040px;
  margin: 20px;

  button:last-child {
    margin-left: 16px;
    ${mobile} {
      margin-right: 20px;
    }
  }
`

export const Wrapper = styled(Grid).attrs({
  container: true,
  spacing: 1
})`
  width: 100%;
  max-width: 1040px;
  min-height: 150px;
  margin: auto;
`
