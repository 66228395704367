import { createSlice } from '@reduxjs/toolkit'
import { SCHEDULER_STEPS } from './constants'

const date = SCHEDULER_STEPS.Date.StepNumber
const professional = SCHEDULER_STEPS.Professional.StepNumber
const modality = SCHEDULER_STEPS.Modality.StepNumber
const user = SCHEDULER_STEPS.User.StepNumber
const checkout = SCHEDULER_STEPS.Checkout.StepNumber
const totalSteps = Object.keys(SCHEDULER_STEPS).length

const initialState = {
  [modality]: null,
  [date]: {
    day: null,
    hour: null
  },
  [professional]: {
    id: null,
    book: null
  },
  [user]: {
    name: null
  },
  [checkout]: {
    transactionId: null,
    transactionValue: null
  }
}

const resetStates = (currentStep, state) => {
  for (let nextStep = currentStep + 1; nextStep < totalSteps; nextStep = nextStep + 1) {
    if (nextStep !== user) {
      state[nextStep] = initialState[nextStep]
    }
  }
}

export const schedulingsSlice = createSlice({
  name: 'schedulings',
  initialState,
  reducers: {
    resetScheduler: (state, action) => {
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    },
    setCheckout: (state, action) => {
      state[checkout] = action.payload
    },
    setDate: (state, action) => {
      state[date] = { ...state[date], ...action.payload }
      resetStates(date, state)
    },
    setModality: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state[modality] = action.payload
      resetStates(modality, state)
    },
    setProfessional: (state, action) => {
      state[professional] = action.payload
      resetStates(professional, state)
    },
    setUser: (state, action) => {
      state[user].name = action.payload
    },
    setValues: (state, action) => {
      state = Object.assign(state, action.payload)
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  resetScheduler,
  setCheckout,
  setDate,
  setModality,
  setProfessional,
  setUser,
  setValues
} = schedulingsSlice.actions

export default schedulingsSlice.reducer
