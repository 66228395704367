import { getLocalDate } from 'common/utils'

export const modalities = [{
  name: 'VIDEOCHAMADA'
}, {
  name: 'PRESENCIAL'
}]

export const SCHEDULER_STEPS = {
  Modality: {
    name: 'Modalidade',
    StepNumber: 0,
    getValue: value => value
  },
  Professional: {
    name: 'Profissional',
    StepNumber: 1,
    getValue: value => value?.name
  },
  Date: {
    name: 'Data e horário',
    StepNumber: 2,
    getValue: date => {
      let answer = null

      if (date?.day?.length >= 10) {
        answer = getLocalDate(date.day.substr(0, 10))
      }
      if (date?.hour?.length > 0) {
        answer += ` às ${date?.hour}`
      }

      return answer
    }
  },
  User: {
    name: 'Usuário',
    StepNumber: 3,
    getValue: user => user?.name
  },
  Checkout: {
    name: 'Pagamento',
    StepNumber: 4,
    getValue: checkout => checkout?.transactionId ? 'Sim' : ''
  }
}

const stepsList = Object.entries(SCHEDULER_STEPS).map(([key]) => key)
export const SCHEDULER_STEPS_INDEX = {
  CHECKOUT: stepsList.indexOf('Checkout'),
  DATE: stepsList.indexOf('Date'),
  MODALITY: stepsList.indexOf('Modality'),
  PROFESSIONAL: stepsList.indexOf('Professional'),
  USER: stepsList.indexOf('User')
}
