import {
  Avatar as MuiAvatar,
  Box,
  Button,
  Divider,
  FormLabel,
  Grid
} from '@material-ui/core'
import AtlassianCalendar from '@atlaskit/calendar'
import styled from 'styled-components'
import ThreeDotsLoaderImage from 'assets/three-dots.svg'
import { mobile } from 'theme/responsive'

const PageTitleSize = {
  big: '32px',
  default: '24px',
  small: '18px'
}

export const Avatar = styled(MuiAvatar)`
  margin: ${({ mt, mr, mb, ml }) =>
  `${mt ? `${mt}px` : 0} ${mr ? `${mr}px` : 0} ${mb ? `${mb}px` : 0} ${ml ? `${ml}px` : 0}`
  };
  ${({ my }) => my && `margin: ${my}px auto !important;`}
  ${({ mx }) => mx && `margin: auto ${mx}px !important;`}
  ${({ size }) => size && `
    width: ${size}px !important;
    height: ${size}px !important;
  `}
`

export const BookCard = styled.div`
  border: 0.5px solid #75787B;
  color: black;
  font-size: 14px;
  margin-top: 10px;

  .book-heading {
    border-bottom: 0.5px solid #75787B;
    font-weight: 600;
    padding: 12px 0;
    text-align: center;
  }

  .book-body {
    align-items: center;
    display: flex;
    padding: 10px 0;
    overflow-x: auto;
    text-align: center;

    span {
      border: solid 1px #000000;
      cursor: pointer;
      display: inline-flex;
      font-size: 12px;
      font-weight: 600;
      padding: 10px 15px;
      margin: 0 10px;
      transition: 0.2s;

      &:hover,
      &.selected {
        background-color: #d0c5b5;
      }
    }
  }
`

export const Calendar = styled(AtlassianCalendar)`
  width: calc(100% - 30px);

  [role="grid"] {
    padding: 10px 0px;
    width: 100%;
  }

  [role="presentation"] {
    width: 100%;
    & > div:first-child {
      display: flex;
      flex-direction: row;
      justify-content: center;

      span {
        font-size: 0.75em;
        max-width: 30px;
        min-width: 30px;
        padding: 2px 0;
      }
    }

    [role="grid"] {
      width: 100%;

      button {
        font-size: 10px;
        font-weight: 600;
        padding: 4px 6px;

        &[data-disabled] {
          color: #a5a3a3;
        }

        &[data-today] {
          &::after {
            background-color: ${({ theme }) => theme.palette.primary.main};
            height: 1px;
          }

          &[data-disabled]::after {
            background-color: #a5a3a3;
          }
        }
      }
    }
  }
`

export const InputError = styled.div`
  color: red;
  font-size: 0.85em;
  margin-left: 2px;
  margin-top: 2px;
  ${({ errorStyle }) => errorStyle === 'absolute' && 'position: absolute; zIndex: 1; '}
`

export const PageTitle = styled.h2`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ big, small }) => (!big && !small)
    ? PageTitleSize.default
    : `${big ? PageTitleSize.big : PageTitleSize.small}`};
  font-weight: ${({ bold }) => bold ? 'bold' : '300'};
  margin-top: 0px;
  ${({ mb }) => mb !== undefined && `margin-bottom: ${mb}px;`}
`

export const PageWrapper = styled(Grid).attrs({
  container: true,
  spacing: 1
})`
  flex-direction: column;
  width: 100%;
  max-width: 1040px;
  min-height: 150px;
  margin: auto;

  ${mobile} {
    .grid-wrapper {
      width: 100% !important;
    }
  }
`

export const StyledBox = styled(Box)`
  margin: ${({ mt, mr, mb, ml }) =>
    `${mt ? `${mt}px` : 0} ${mr ? `${mr}px` : 0} ${mb ? `${mb}px` : 0} ${ml ? `${ml}px` : 0}`
  } !important;
`

export const StyledButton = styled(Button)`
  margin: ${({ mt, mr, mb, ml }) =>
    `${mt ? `${mt}px` : 0} ${mr ? `${mr}px` : 0} ${mb ? `${mb}px` : 0} ${ml ? `${ml}px` : 0}`
  } !important;

  ${({ withIcon }) => withIcon && `
    svg { margin-right: 10px; }
  `}
`

export const SpacedTextButton = styled(StyledButton)`
  letter-spacing: 0.08857em !important;
`

export const StyledDivider = styled(Divider)`
  ${({ my }) => my && `margin: ${my}px auto !important;`}
  ${({ mx }) => mx && `margin: auto ${mx}px !important;`}
`

export const StyledGrid = styled(Grid)`
  ${({ bottomline }) => bottomline && `border-bottom: ${bottomline};`}
  ${({ flex }) => flex && 'display: flex;'}
  margin: ${({ mt, mr, mb, ml }) =>
    `${mt ? `${mt}px` : 0} ${mr ? `${mr}px` : 0} ${mb ? `${mb}px` : 0} ${ml ? `${ml}px` : 0} !important;`
  };
  ${({ py }) => py && `
    padding-bottom: ${py}px !important;
    padding-top: ${py}px !important;
  `}
`

export const StyledLabel = styled(FormLabel)`
  display: inherit;
  font-weight: bold;
  margin-bottom: 7px;
`

export const ThreeDotsLoader = styled.img.attrs({
  src: ThreeDotsLoaderImage
})`
  width: 60px;
  ${({ my }) => my && `margin: ${my}px auto !important;`}
  ${({ color }) => color && `color: ${color};`}
  ${({ small }) => small && 'width: 45px;'}
  ${({ medium }) => medium && 'width: 60px;'}
  ${({ big }) => big && 'width: 75px;'}
`
