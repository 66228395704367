import { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import CheckIcon from '@material-ui/icons/Check'
import { format } from 'date-fns'
import ArrowBullet from 'assets/images/arrow_right_black.png'
import { Calendar } from 'components/Styled'
import useCalendarNavigation from 'common/hooks/useCalendarNavigation'
import { Answer } from '../styled'
import { SCHEDULER_STEPS_INDEX } from '../../constants'

const minDate = format(new Date(), 'yyyy-MM-dd')

function StepperItem ({
  date,
  onDisabledDateFilter,
  selected,
  onDateSelected,
  step,
  value
}) {
  const professional = useSelector(state => state.schedulings[SCHEDULER_STEPS_INDEX.PROFESSIONAL])

  const handleDateSelected = onDateSelected || (() => null)
  const isAnswered = (step, value) => step.getValue(value)?.length > 0
  const showCalendar = selected && step?.index === SCHEDULER_STEPS_INDEX.DATE

  // custom hook to handle calendar navigation listeners
  // and fetch the professional's book availability options
  useCalendarNavigation([showCalendar], professional?.id)

  return (
    <li>
      {selected && <img src={ArrowBullet} className='item-selected' />}
      <span className={selected ? 'selected' : ''}>{step.name}</span>
      {value && isAnswered(step, value) && (
        <Answer>
          <CheckIcon fontSize='small' className='item-checked' />
          {step?.getValue(value)}
        </Answer>
      )}
      {showCalendar && (
        <Calendar
          day={0}
          disabledDateFilter={onDisabledDateFilter}
          locale='pt-BR'
          minDate={minDate}
          onSelect={handleDateSelected}
          selected={date?.day ?? new Date().toISOString().slice(0, 10)}
        />
      )}
    </li>
  )
}

StepperItem.propTypes = {
  date: PropTypes.object,
  onDateSelected: PropTypes.func.isRequired,
  onDisabledDateFilter: PropTypes.func,
  selected: PropTypes.bool.isRequired,
  step: PropTypes.object.isRequired,
  value: PropTypes.any
}

export default memo(StepperItem)
