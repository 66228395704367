import { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Box, Button, Grid } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useFormik } from 'formik'
import { requestExceptionHandler, validateEmail } from 'common/utils'
import InputText from 'components/InputText'
import MuiAlertDialog from 'components/MuiAlertDialog'
import { setNavigateTo, setUser as appSetUser } from 'modules/app/appSlice'
import { setUser as schedulingsSetUser } from 'modules/schedulings/schedulings.store'
import routes from 'services/api/routes'
import { NativeLoginSubmit } from '../../styled'
import { validationSchema } from './validationSchema'
import { StyledBox } from 'components/Styled'

const DIALOG_INITIAL_DATA = {
  open: false,
  title: 'RECUPERAÇÃO DE ACESSO',
  message: 'Preencha com seu e-mail para continuar',
  value: null
}

const RECOVERY_REQUEST_RESULTS = {
  EMAIL_SENT: 'email-sent',
  CODE_VALIDATED: 'code-validated'
}

function LoginForm ({ httpClient, navigateTo }) {
  const dispatch = useDispatch()
  const [dialog, setDialog] = useState(DIALOG_INITIAL_DATA)
  const [error, setError] = useState(null)
  const { errors, handleChange, submitForm, ...formik } = useFormik({
    initialValues: {
      email: '',
      password: '',
      recoveryEmail: '',
      recoveryCode: '',
      recoveryNewPassword: '',
      recoveryNewPasswordConfirm: ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      // native app authentication
      return httpClient
        .post(routes.authentication.authWithEmail, values, { unhandled: true })
        .then(user => {
          if (user?.token) {
            httpClient.setBearerToken(user.token)
            dispatch(appSetUser(user))
            dispatch(schedulingsSetUser(user.name))

            if (navigateTo) {
              dispatch(setNavigateTo(navigateTo))
            }
          }
        })
        .catch(err => {
          const { message } = requestExceptionHandler(err)
          setError(message)
        })
    }
  })

  const isRecoveryRequestSent = dialog.value === RECOVERY_REQUEST_RESULTS.EMAIL_SENT
  const isRecoveryCodeValidated = dialog.value === RECOVERY_REQUEST_RESULTS.CODE_VALIDATED

  const handlePasswordRecovery = () => {
    if (!isRecoveryRequestSent && !isRecoveryCodeValidated) handlePasswordRecoveryRequest()
    else if (!isRecoveryCodeValidated) handlePasswordRecoveryCodeSubmit()
    else setDialog(DIALOG_INITIAL_DATA)
  }

  const handlePasswordRecoveryRequest = async () => {
    const { recoveryEmail: email } = formik.values

    if (email.length === 0) {
      formik.setFieldError('recoveryEmail', 'Informe o seu e-mail.')
      return
    }

    await formik.validateField('recoveryEmail')
    if (!validateEmail(email)) return

    return httpClient
      .post(routes.users.passwordRecovery, { email }, { unhandled: true })
      .then(result => {
        if (result?.success) {
          setDialog(dialog => ({
            ...dialog,
            message: 'Informe abaixo o código que enviamos por e-mail',
            value: RECOVERY_REQUEST_RESULTS.EMAIL_SENT
          }))
        }

        if (error?.length > 0) setError(null)
      })
      .catch(err => {
        const { message } = requestExceptionHandler(err)
        setDialog(dialog => ({ ...dialog, open: false }))
        setError(message)
      })
  }

  const handlePasswordRecoveryCodeSubmit = async () => {
    const {
      recoveryEmail: email,
      recoveryCode: code,
      recoveryNewPassword,
      recoveryNewPasswordConfirm
    } = formik.values

    if (code.length === 0) {
      formik.setFieldError('recoveryCode', 'Informe corretamente o código.')
      return
    } else if (recoveryNewPassword.length < 6 || recoveryNewPassword.length > 12) {
      formik.setFieldError('recoveryNewPassword', 'Informe uma nova senha com 6 a 12 caracteres.')
      return
    } else if (recoveryNewPasswordConfirm !== recoveryNewPassword) {
      formik.setFieldError('recoveryNewPasswordConfirm', 'Confirme corretamente sua nova senha.')
      return
    }

    const payload = {
      code,
      email,
      newPassword: recoveryNewPassword
    }

    return httpClient
      .post(routes.users.passwordRecoveryValidation, payload, { unhandled: true })
      .then(result => {
        if (result?.success) {
          setDialog(dialog => ({
            ...dialog,
            message: 'Muito bem! A recuperação foi realizada com sucesso. Tente realizar o seu login.',
            value: RECOVERY_REQUEST_RESULTS.CODE_VALIDATED
          }))
        }

        if (error?.length > 0) setError(null)
      })
      .catch(err => {
        const { message } = requestExceptionHandler(err)
        setDialog(dialog => ({ ...dialog, open: false }))
        setError(message)
      })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid container item spacing={2} xs>
          <Grid item md={6} xs={12}>
            <InputText
              fullWidth
              required
              label='E-MAIL CADASTRADO'
              name='email'
              type='email'
              placeholder='email@email.com'
              error={errors.email}
              onChange={handleChange}
              value={formik.values.email}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Grid item xs={12}>
              <InputText
                fullWidth
                required
                label='SENHA'
                name='password'
                type='password'
                placeholder='sua senha'
                error={errors.password}
                onChange={handleChange}
                value={formik.values.password}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <Box display='flex' mb={2} justifyContent='space-between'>
            <Button
              color='primary'
              onClick={() => setDialog(dialog => ({ ...dialog, open: true }))}
            >
              Recuperar acesso
            </Button>
            {error !== null && (
              <span className='error red'>{error}</span>
            )}
          </Box>
          </Grid>
        </Grid>

        <NativeLoginSubmit onClick={submitForm}>
          <ArrowForwardIcon htmlColor='white' />
        </NativeLoginSubmit>
      </Grid>

      {dialog.open && (
        <MuiAlertDialog
          disableEscapeKeyDown
          open
          title={dialog.title}
          message={dialog.message}
          onClose={() => null}
          onCancel={() => setDialog(DIALOG_INITIAL_DATA)}
          onConfirm={handlePasswordRecovery}
          labelCancel='Cancelar'
          labelConfirm='Confirmar'
        >
          <StyledBox mt={30} mb={30}>
            {(!isRecoveryRequestSent && !isRecoveryCodeValidated) && (
              <InputText
                fullWidth
                required
                label='E-MAIL'
                name='recoveryEmail'
                type='email'
                placeholder='email@email.com'
                error={errors.recoveryEmail}
                onChange={handleChange}
                value={formik.values.recoveryEmail}
              />
            )}

            {(isRecoveryRequestSent && !isRecoveryCodeValidated) && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <InputText
                    fullWidth
                    required
                    label='CÓDIGO'
                    name='recoveryCode'
                    error={errors.recoveryCode}
                    onChange={handleChange}
                    value={formik.values.recoveryCode}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputText
                    fullWidth
                    required
                    label='NOVA SENHA'
                    name='recoveryNewPassword'
                    type='password'
                    error={errors.recoveryNewPassword}
                    onChange={handleChange}
                    value={formik.values.recoveryNewPassword}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputText
                    fullWidth
                    required
                    label='CONFIRME A SENHA'
                    name='recoveryNewPasswordConfirm'
                    type='password'
                    error={errors.recoveryNewPasswordConfirm}
                    onChange={handleChange}
                    value={formik.values.recoveryNewPasswordConfirm}
                  />
                </Grid>
              </Grid>
            )}
          </StyledBox>

        </MuiAlertDialog>
      )}
    </>
  )
}

LoginForm.propTypes = {
  httpClient: PropTypes.object.isRequired,
  navigateTo: PropTypes.string
}

export default LoginForm
