import { useState } from 'react'
import { Grid, Select } from '@material-ui/core'
import PropTypes from 'prop-types'
import { InputError, StyledLabel } from 'components/Styled'

function InputSelect ({
  children,
  errorStyle,
  formik,
  label,
  onChange,
  required,
  stateless,
  testId,
  variant,
  ...rest
}) {
  const error = formik ? formik?.errors?.[rest?.name] : null
  const inputVariant = variant || 'outlined'
  const [inputValue, setInputValue] = useState(rest.value)
  const handleParentChange = onChange || (() => null)

  const handleLocalChange = evt => {
    if (!stateless) setInputValue(evt.target.value)
    handleParentChange(evt) // debounced parent change handler
  }

  const fieldProps = !stateless ? { value: inputValue } : {}
  const inputProps = { ...rest?.inputProps, 'data-testid': testId, autoComplete: 'off' }

  return (
    <>
      {label && (
        <StyledLabel>
          {label} {required && <span className='red'>*</span>}
        </StyledLabel>
      )}
      <Grid item xs={12} style={{ position: 'relative' }}>
        <Select
          onChange={handleLocalChange}
          variant={inputVariant}
          {...rest}
          {...fieldProps}
          inputProps={inputProps}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            'aria-autocomplete': 'off',
            getContentAnchorEl: null
          }}
        >
          {children}
        </Select>

        {error && (
          <InputError
            data-testid={`input-${rest?.name}-error`}
            errorStyle={errorStyle}
          >
            {error}
          </InputError>
        )}
      </Grid>
    </>
  )
}

InputSelect.propTypes = {
  children: PropTypes.node.isRequired,
  formik: PropTypes.object,
  errorStyle: PropTypes.oneOf(['default', 'absolute']),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  stateless: PropTypes.bool,
  testId: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  value: PropTypes.string
}

export default InputSelect
