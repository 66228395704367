import { useState } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import PropTypes from 'prop-types'
import { InputError, StyledLabel } from 'components/Styled'

function InputAutocomplete ({
  children,
  errorStyle,
  formik,
  label,
  onChange,
  placeholder,
  required,
  stateless,
  testId,
  variant,
  ...rest
}) {
  const error = formik ? formik?.errors?.[rest?.name] : null
  const inputVariant = variant || 'outlined'
  const [inputValue, setInputValue] = useState(rest.value)
  const handleParentChange = onChange || (() => null)

  const handleLocalChange = (evt, value) => {
    if (!stateless) setInputValue(value)

    handleParentChange({
      target: {
        name: rest.name,
        value: value?.name
      }
    }) // debounced parent change handler
  }

  const fieldProps = !stateless ? { value: inputValue } : {}
  const inputProps = { ...rest?.inputProps, 'data-testid': testId }

  return (
    <>
      {label && (
        <StyledLabel>
          {label} {required && <span className='red'>*</span>}
        </StyledLabel>
      )}
      <Grid item xs={12} style={{ position: 'relative' }}>
        <Autocomplete
          onChange={handleLocalChange}
          inputProps={inputProps}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              variant={inputVariant}
              autoComplete='off'
            />
          )}
          {...rest}
          {...fieldProps}
        />

        {error && (
          <InputError
            data-testid={`input-${rest?.name}-error`}
            errorStyle={errorStyle}
          >
            {error}
          </InputError>
        )}
      </Grid>
    </>
  )
}

InputAutocomplete.propTypes = {
  children: PropTypes.node,
  formik: PropTypes.object,
  errorStyle: PropTypes.oneOf(['default', 'absolute']),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  stateless: PropTypes.bool,
  testId: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  value: PropTypes.object
}

export default InputAutocomplete
