import PropTypes from 'prop-types'
import MaskedInput from 'react-input-mask'

const MaskedDateInput = ({ inputRef, ...rest }) => {
  return <MaskedInput autoComplete='off' {...rest} ref={inputRef} mask='99/99/9999' />
}

MaskedDateInput.propTypes = {
  inputRef: PropTypes.func.isRequired
}

export default MaskedDateInput
