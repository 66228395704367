import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { SCHEDULER_STEPS_INDEX } from './constants'

export default function useScheduler ({ maxStep = 6 }) {
  const values = useSelector(state => state.schedulings)
  const appUser = useSelector(state => state.app.user)
  const [currentStep, setStep] = useState({ step: 0, previousStep: -1 })
  const { step, previousStep } = currentStep

  const isNotAnswered = useMemo(() => {
    if (step === SCHEDULER_STEPS_INDEX.DATE) {
      return !values[step]?.day || !values[step]?.hour
    } else if (step === SCHEDULER_STEPS_INDEX.SPECIALTY) {
      return !values[step]?.specialty?.id
    } else if (step === SCHEDULER_STEPS_INDEX.USER) {
      return !values[step]?.name
    } else {
      return !values[step]
    }
  }, [step, values])

  const handleStepBack = useCallback(() => {
    if (step > 0) setStep({ step: step - 1, previousStep: step })
  }, [step, values])

  const handleStepNext = useCallback(() => {
    const value = values[step]
    if (!value) return

    if (step <= maxStep) {
      setStep({ step: step + 1, previousStep: step })
    }
  }, [step, values])

  // didUpdate: few checks to query data or jump to next step
  useEffect(() => {
    if (appUser?.name && SCHEDULER_STEPS_INDEX.USER === step) {
      if (previousStep > step) handleStepBack()
      else handleStepNext()
    } else if (!appUser?.name && SCHEDULER_STEPS_INDEX.CHECKOUT === step) {
      handleStepBack()
    }
  }, [step, appUser?.name])

  return {
    handleStepBack,
    handleStepNext,
    isNotAnswered,
    step
  }
}
