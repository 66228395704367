export const colors = {
  brandBlack: '#3D3935',
  primary: {
    light: '#f0eae1',
    mainLight: '#bda995',
    main: '#3D3935'
    // dark: será calculada com base em palette.primary.main,
    // contrastText: será calculada para contrastar com palette.primary.main
  },
  secondary: {
    // light: será calculada com base em palette.primary.main,
    main: '#953838'
    // dark: será calculada com base palette.secondary.main,
    // contrastText: será calculada para contrastar com palette.primary.main
  }
}
