import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { Provider as StoreProvider } from 'react-redux'
import Router from 'modules/app/Router'
import { store } from 'store'
import theme from './theme'

function App () {
  return (
    <MaterialThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <StoreProvider store={store}>
          <Router />
        </StoreProvider>
      </StyledThemeProvider>
    </MaterialThemeProvider>
  )
}

export default App
