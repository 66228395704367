import * as Yup from 'yup'
import { validateLocalDate, validateCPF, validatePhone } from './utils'

Yup.addMethod(Yup.string, 'validateBirthdate', function (errorMessage) {
  return this.test('test-birthdate', errorMessage, validateLocalDate)
})

Yup.addMethod(Yup.string, 'validateCPF', function (errorMessage) {
  return this.test('test-cpf-number', errorMessage, validateCPF)
})

Yup.addMethod(Yup.string, 'validatePhone', function (errorMessage) {
  return this.test('test-phone-number', errorMessage, validatePhone)
})

export default Yup
