import PropTypes from 'prop-types'
import { Button } from './styled'

const brandColors = {
  facebook: '#2462A8',
  google: '#E33E2B'
}

function SocialButton (props) {
  const backgroundColor = brandColors[props?.brand] || '#333'
  return <Button fullWidth variant='contained' {...props} style={{ backgroundColor, color: 'white' }} />
}

SocialButton.propTypes = {
  brand: PropTypes.oneOf(['facebook', 'google']).isRequired
}

export default SocialButton
