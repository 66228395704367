import { useEffect, useMemo, useState } from 'react'
import {
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import defaultAvatar from 'assets/images/avatar.png'
import useApi from 'common/hooks/useApi'
import { Avatar, StyledGrid } from 'components/Styled'
import { setUserMissingInfo } from 'modules/app/appSlice'
import { SCHEDULER_STEPS, SCHEDULER_STEPS_INDEX } from 'modules/schedulings/constants'
import routes from 'services/api/routes'
import * as Steps from './steps'
import { ProfessionalCard, ProfessionalInfo, StepTitle } from '../styled'
import 'react-credit-cards/es/styles-compiled.css'

const CHECKOUT_STEPS = {
  address: 'Endereço',
  info: 'Informações necessárias',
  payment: 'Pagamento'
}

function Checkout () {
  const [, httpClient] = useApi()
  const dispatch = useDispatch()
  const [fetchingMissingInfo, setFetchingMissingInfo] = useState(true)
  const [missingData, setMissingData] = useState({})
  const [step, setStep] = useState(0)

  const date = useSelector(state => state.schedulings[SCHEDULER_STEPS_INDEX.DATE])
  const professional = useSelector(state => state.schedulings[SCHEDULER_STEPS_INDEX.PROFESSIONAL])
  const { user } = useSelector(state => state.app)

  const checkoutSteps = useMemo(() => {
    const missingInfo = Object
      .keys(user?.missingInfo)
      .filter(key => user.missingInfo[key])

    const steps = []

    if (missingInfo.includes('document') || missingInfo.includes('phoneNumber')) {
      steps.push(CHECKOUT_STEPS.info)
    }
    if (missingInfo.includes('billingAddress')) {
      steps.push(CHECKOUT_STEPS.address)
    }

    return [...steps, CHECKOUT_STEPS.payment]
  }, [user?.missingInfo])

  /**
   * Stores the profile/user missing data
   * like address, document and phone number
   */
  const handleSubmit = (sectionName, values) => {
    setMissingData(current => ({ ...current, [sectionName]: values }))
    setStep(current => current + 1)
  }

  /**
   * @didMount
   * fetch user essential informations for checkout
   */
  useEffect(() => {
    httpClient
      .get(routes.users.missingInfo, null, { unhandled: true })
      .then(data => {
        setFetchingMissingInfo(false)
        if (Object.keys(data).length === 4) dispatch(setUserMissingInfo(data))
      })
      .catch(err => console.error(err.message))
  }, [])

  if (fetchingMissingInfo) return null

  return (
    <div>
      <StepTitle big mb={10}>
        Seja bem vindo(a), <strong>{user.name}</strong>!
      </StepTitle>
      <Typography variant='h6'>
        Para concluir, escolha como deseja pagar a consulta:
      </Typography>

      <StyledGrid container mt={24} spacing={1}>
        <Grid item xs={12}>
          <ProfessionalCard>
            <div className='info'>
              <Avatar
                alt={professional?.name}
                size={120}
                src={professional?.avatar || defaultAvatar}
              />
              <ProfessionalInfo>
                <h4>Dr(a). {professional?.name}</h4>
                <div className='description'>
                  <strong>DATA:</strong> {SCHEDULER_STEPS.Date.getValue(date)} <br />
                  <strong>PACIENTE:</strong> {user.name} <br />
                </div>
              </ProfessionalInfo>
            </div>
          </ProfessionalCard>
        </Grid>
      </StyledGrid>

      {checkoutSteps.length > 1 && (
        <Stepper alternativeLabel activeStep={step}>
          {checkoutSteps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {checkoutSteps[step] === CHECKOUT_STEPS.info && (
        <Steps.Info missingUserInfo={user?.missingInfo} onSubmit={handleSubmit} />
      )}
      {checkoutSteps[step] === CHECKOUT_STEPS.address && (
        <Steps.Address onSubmit={handleSubmit} />
      )}
      {checkoutSteps[step] === CHECKOUT_STEPS.payment && (
        <Steps.Payment missingData={missingData} />
      )}
    </div>
  )
}

export default Checkout
