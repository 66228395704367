import { isValid } from 'date-fns'

export default function validateLocalDate (date = '') {
  const parts = date.split('/')
  if (parts.length !== 3) {
    console.error('Data informada precisa estar em formato brasileiro. Ex: 01/01/2021')
    return false
  }

  const currentYear = new Date().getFullYear
  const informedYear = Number(parts[2])

  if (String(informedYear).length !== 4 || informedYear <= 1900 || informedYear >= currentYear) {
    console.error(`Ano (${informedYear}]) da data informada parece estar incorreto.`)
    return false
  }

  if (!isValid(new Date(`${parts[2]}-${parts[1]}-${parts[0]}`))) {
    return false
  }

  return true
}
