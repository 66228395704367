import { useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { useFormik } from 'formik'
import useApi from 'common/hooks/useApi'
import { internationalizeDate, requestExceptionHandler } from 'common/utils'
import AccountCreated from './components/AccountCreated'
import InputText from 'components/InputText'
import { StyledGrid } from 'components/Styled'
import MaskedCpfInput from 'components/MaskedCpfInput'
import MaskedDateInput from 'components/MaskedDateInput'
import MaskedPhoneInput from 'components/MaskedPhoneInput'
import routes from 'services/api/routes'
import { validationSchema } from './validationSchema'

export default function Register () {
  const [error, setError] = useState(null)
  const [registered, setIsRegistered] = useState(false)
  const [isLoading, httpClient] = useApi()
  const { errors, handleChange, submitForm, ...formik } = useFormik({
    initialValues: {
      cpf: '',
      name: '',
      birthdate: '',
      email: '',
      phone: '',
      password: '',
      passwordConfirmation: ''
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      const data = { ...values }
      data.birthdate = internationalizeDate(data.birthdate)
      data.document = data.document.replace(/[\D]/g, '')
      data.phone = `+55${data.phone.replace(/[\D]/g, '')}`

      return httpClient
        .post(routes.users.create, data, { unhandled: true })
        .then(created => setIsRegistered(true))
        .catch(err => {
          const { message } = requestExceptionHandler(err)
          setError(message)
        })
    }
  })

  if (registered) {
    return <AccountCreated email={formik.values.email} />
  }

  return (
    <Grid container spacing={2}>
      <StyledGrid item mb={20} xs={12}>
        <InputText
          fullWidth
          required
          label='NOME COMPLETO'
          name='name'
          placeholder='Sem abreviações'
          testId='input-name'
          error={errors.name}
          onChange={handleChange}
          value={formik.values.name}
        />
      </StyledGrid>

      <StyledGrid item mb={20} md={6} xs={12}>
        <InputText
          fullWidth
          required
          label='CPF'
          name='document'
          testId='input-document'
          error={errors.document}
          onChange={handleChange}
          value={formik.values.document}
          InputProps={{
            inputComponent: MaskedCpfInput
          }}
        />
      </StyledGrid>

      <StyledGrid item mb={20} md={6} xs={12}>
        <InputText
          fullWidth
          required
          label='DATA DE NASCIMENTO'
          name='birthdate'
          testId='input-birthdate'
          error={errors.birthdate}
          onChange={handleChange}
          value={formik.values.birthdate}
          InputProps={{
            inputComponent: MaskedDateInput
          }}
        />
      </StyledGrid>

      <StyledGrid item mb={20} md={6} xs={12}>
        <InputText
          fullWidth
          required
          label='E-MAIL'
          name='email'
          placeholder='email@email.com'
          type='email'
          testId='input-email'
          error={errors.email}
          onChange={handleChange}
          value={formik.values.email}
        />
      </StyledGrid>

      <StyledGrid item mb={20} md={6} xs={12}>
        <InputText
          fullWidth
          required
          label='TELEFONE'
          name='phone'
          testId='input-phone'
          error={errors.phone}
          onChange={handleChange}
          value={formik.values.phone}
          InputProps={{
            inputComponent: MaskedPhoneInput
          }}
        />
      </StyledGrid>

      <StyledGrid container mb={20} spacing={2}>
        <Grid item md={6} xs={12}>
          <InputText
            fullWidth
            required
            label='SENHA'
            name='password'
            placeholder='******'
            type='password'
            testId='input-password'
            error={errors.password}
            onChange={handleChange}
            value={formik.values.password}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputText
            fullWidth
            required
            label='CONFIRMAR SENHA'
            name='passwordConfirmation'
            placeholder='******'
            type='password'
            testId='input-passwordConfirmation'
            error={errors.passwordConfirmation}
            onChange={handleChange}
            value={formik.values.passwordConfirmation}
          />
        </Grid>
      </StyledGrid>

      {error !== null && (
        <StyledGrid container item mb={10} mt={10} spacing={2}>
          <span className='error red'>{error}</span>
        </StyledGrid>
      )}

      <Grid container item justifyContent='flex-end'>
        <Button
          color='primary'
          variant='contained'
          disabled={isLoading}
          onClick={submitForm}
        >
          CADASTRAR-SE
        </Button>
      </Grid>
    </Grid>
  )
}
