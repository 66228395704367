import styled from 'styled-components'

export const Answer = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  margin: 5px 5px 0 0;
  padding-left: 25px;
  position: relative;
  text-transform: uppercase;

  button {
    margin-left: 10px;
    padding: 10px;

    .MuiIconButton-label svg {
      left: 0px;
      top: 0px;
    }
  }

  svg {
    position: absolute;
    top: 0px;
    left: 0px;
  }
`

export const Menu = styled.ul`
  list-style: none;
  margin: 0px 0px 20px;
  padding-left: 15px;
  
  li {
    font-size: 12px;
    margin: 0px 0px 15px;
    min-height: 30px;
    position: relative;

    img.item-selected {
      position: absolute;
      z-index: 9;
      left: -25px;
      top: -5px;
    }

    span {
      color: ${({ theme }) => theme.palette.primary.mainLight};
      cursor: pointer;
      display: block;
      font-size: 13px;

      &.selected {
        color: ${({ theme }) => theme.palette.primary.main} !important;
        font-weight: bold;
      }
    }
  }
`
